import instance from "./instance";

export type ApiAuthor = {
  name: string;
  email: string;
  phone: string;
};

export async function apiBecomeAuthor(data: ApiAuthor) {
  return instance.post("author", data);
}
