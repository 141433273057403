/* eslint-disable @typescript-eslint/no-explicit-any */
import classNames from "classnames/bind";
import ModalWrapper from "components/ModalWrapper/ModalWrapper";
import { useFormik } from "formik";
import React, { useRef } from "react";
import * as Yup from "yup";
import { useMutation } from "react-query";
import { ApiAuthor, apiBecomeAuthor } from "api/author";
import { useTranslation } from "react-i18next";
import { getPhoneError } from "src/shared/ui/PhoneInput/lib";
import { PhoneInput } from "src/shared/ui/PhoneInput/PhoneInput";
import { Input } from "src/shared/ui/Input/Input";
import { createNotification } from "src/providers/NotificationsProvider";
import { Button } from "src/shared/ui/Button/Button";
import Styles from "./ModalBecomeAuthor.module.scss";
import { useBecomeModalAuthor } from "./lib";

const cnb = classNames.bind(Styles);

export const ModalBecomeAuthor = () => {
  const { mutate, isLoading } = useMutation((data: ApiAuthor) => apiBecomeAuthor(data));

  const { t } = useTranslation();

  const itiRef = useRef<any>();

  const { close, isOpen } = useBecomeModalAuthor();

  const initialValues = {
    name: "",
    email: "",
    phone: ""
  };

  const commonFormValidationSchema = Yup.object().shape({
    name: Yup.string()
      .required(t("errors.required"))
      .max(256, t("errors.longThen", { length: 256 })),
    email: Yup.string()
      .required(t("errors.required"))
      .email(t("errors.invalidEmail"))
      .max(256, t("errors.longThen", { length: 256 }))
  });

  const commonInfoForm = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: commonFormValidationSchema,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: () => {
      const error = getPhoneError(itiRef.current.iti);

      if (error) {
        commonInfoForm.setFieldError("phone", error);
        return;
      }

      mutate(
        {
          ...commonInfoForm.values,
          phone: itiRef.current.iti.getNumber().slice(1)
        },
        {
          onSuccess: () => {
            createNotification("success", "Заявка отправлена");

            close();
            commonInfoForm.resetForm();
          }
        }
      );
    }
  });

  const { errors: commonErrors } = commonInfoForm;

  return (
    <ModalWrapper
      isVisible={isOpen}
      closeModal={() => {
        close();
      }}
      submit={() => {}}
      isLoading={false}
      customControls={() => <></>}
      className={cnb("modalFitWelcome")}
      containerClassName={cnb("modalFitWelcome")}
      contentClassName={cnb("main")}
    >
      <h2 className={cnb("title")}>
        Чтобы стать автором и бесплатно разместить свои курсы на платформе TALANTA, заполните форму.
        Наш менеджер свяжется с вами в течение 24 часов.
      </h2>

      <form onSubmit={commonInfoForm.handleSubmit} className={cnb("form")}>
        <div className={cnb("inputsWrapper")}>
          <Input
            id='name'
            name='name'
            placeholder='ФИО'
            onChange={commonInfoForm.handleChange}
            value={commonInfoForm.values.name}
            error={commonErrors.name}
          />
          <Input
            id='email'
            name='email'
            placeholder={t("profile.generalInformation.email")}
            onChange={commonInfoForm.handleChange}
            value={commonInfoForm.values.email}
            error={commonErrors.email}
          />
        </div>

        <PhoneInput
          ref={itiRef}
          name='phone'
          onChange={commonInfoForm.handleChange}
          value={commonInfoForm.values.phone}
          error={commonErrors.phone}
          type='tel'
        />

        <Button
          style={{
            maxWidth: 300,
            margin: "30px auto 32px auto",
            fontWeight: 400,
            minHeight: 50
          }}
          loading={isLoading}
          type='submit'
          onClick={() => {}}
        >
          Стать автором
        </Button>
      </form>
    </ModalWrapper>
  );
};
