import instance from "api/instance";
import { apiRefreshToken } from "api/login";
import axios, { AxiosRequestHeaders } from "axios";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { actionClearStore } from "store/actions/app";
import { actionSetToken } from "store/actions/login";
import store from "store/index";
import { createNotification } from "./NotificationsProvider";

const InterceptorsProvider: React.FC = ({ children }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const getToken = () => `Bearer ${store.getState().login.token}`;

  instance.interceptors.request.use((config) => {
    const newConfig = config;

    const params: Partial<AxiosRequestHeaders> = {};

    if (store.getState().login.token !== "") {
      params.Authorization = getToken();
    }
    params.Accept = "application/json";
    newConfig.headers = params as AxiosRequestHeaders;

    return newConfig;
  });

  let requestQueue: ((data: string) => void)[] = [];

  function addToQueue(callback: (data: string) => void): void {
    requestQueue.push(callback);
  }

  function executeQueue(token: string): void {
    requestQueue.map((callback) => callback(token));
  }

  let isRefreshedToken = false;

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error?.response?.status === 401 && error.config) {
        if (!isRefreshedToken) {
          isRefreshedToken = true;
          const { login } = store.getState();
          if (login.token?.length && login.refresh_token?.length) {
            apiRefreshToken(login.refresh_token)
              .then((tokenResponse) => {
                isRefreshedToken = false;
                const { data } = tokenResponse.data;
                dispatch(actionSetToken(data.token, data.refresh_token));
                executeQueue(data.token);
                requestQueue = [];
              })
              .catch(() => {
                isRefreshedToken = false;
                dispatch(actionClearStore());
                createNotification("error", t(`response.sessionTimeout`));
                requestQueue = [];
              });
          } else {
            dispatch(actionClearStore());
          }

          if (isRefreshedToken && error.response.config.url === "token/refresh") {
            dispatch(actionClearStore());
            createNotification("error", t(`response.sessionTimeout`));
          }
        }

        return new Promise((resolve) =>
          addToQueue((token: string) => {
            const newError = error;
            newError.config.headers.Authorization = `Bearer ${token}`;
            resolve(axios(newError.config));
          })
        ).then((r) => {
          return r;
        });
      }

      // if (error?.response?.status === 404) {
      //   return history.push("/404");
      // }

      return Promise.reject(error);
    }
  );
  return <>{children}</>;
};

export default InterceptorsProvider;
