import { RoleType } from "reducers/LoginReducer/types";
import instance from "./instance";

interface ApiTokensType {
  data: { data: { token: string; refresh_token: string } };
}

interface ApiLoginType {
  data: { data: { roles: string[]; tokens: { token: string; refresh_token: string } } };
}

export type ApiGetAllRolesData = {
  data: {
    status: number;
    success: boolean;
    data: RoleType[];
    pagination: {
      count: number;
      total: number;
      perPage: number;
      currentPage: number;
      totalPages: number;
      links: [];
    };
  };
};

export async function apiUserLogin(login: string, password: string): Promise<ApiLoginType> {
  return instance.post("login", { email: login, password });
}

export async function apiUserLogout(): Promise<{ data: { message: string } }> {
  return instance.post("logout", {});
}

export async function apiSetCookie(): Promise<{ data: { message: string } }> {
  return instance.get("sanctum/csrf-cookie", {});
}

export async function apiRefreshToken(refresh_token: string): Promise<ApiTokensType> {
  return instance.post(`token/refresh`, { refresh_token });
}

export async function apiResetPassword(email: string): Promise<ApiTokensType> {
  return instance.post(`password/requestReset`, { email });
}

export async function apiUserRegistration(data: {
  email: string;
  password: string;
  password_confirmation: string;
  phone?: string;
  full_name?: string;
}): Promise<ApiTokensType> {
  return instance.post("register", data);
}

export const apiGetAllRoles = (): Promise<ApiGetAllRolesData> => instance.get(`role`);
