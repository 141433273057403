import { ConsumersType, IdType, ProgramType, TeacherType } from "reducers/CourseReducer/types";
import { validate as uuidValidate } from "uuid";

export const restoreItem = <T extends TeacherType | ProgramType | ConsumersType, I extends IdType>(
  data: T[],
  id: I
): T[] => {
  return <T[]>data.map((item) => {
    if (item.id.toString() === id.toString()) {
      const newItem = item;
      delete newItem.status;
      return newItem;
    }
    return item;
  });
};

export const markForDelete = <
  T extends TeacherType | ProgramType | ConsumersType,
  I extends IdType,
  C extends boolean
>(
  data: T[],
  id: I,
  confirmation?: C
): T[] => {
  return <T[]>data.reduce((previousValue, currentValue) => {
    let newList = previousValue;
    const toDeleteID = id.toString();
    const currentID = currentValue.id.toString();
    /**
     * Если элемент пришел с бэка, то помечаем к удалению, остальные полностью убираем из стора
     * */
    if (!uuidValidate(currentID) && currentID === toDeleteID && !confirmation) {
      newList.push({
        ...currentValue,
        status: "removed"
      });
    } else if (currentID === toDeleteID) {
      newList = previousValue;
    } else {
      newList.push({
        ...currentValue
      });
    }
    return newList;
  }, [] as T[]);
};

export const createItem = <T extends Partial<TeacherType> | ProgramType>(
  data: T[],
  actionFields: T,
  isNew: boolean
): T[] => {
  let newList: T[];
  if (data && isNew) {
    const filteredList = data.filter((item) =>
      !item.uuid ? item.id !== actionFields.id : item.uuid !== actionFields.uuid
    );

    newList = [...filteredList, actionFields];
  } else {
    const filteredList = data.filter((item) => {
      return item.id !== actionFields.id;
    });

    newList = [...filteredList, actionFields];
  }
  return newList;
};
