import React from "react";

interface Props {
  name: string;
  Size?: "h1" | "h2" | "h3" | "h4" | "h5";
  marginVertical?: number;
  marginHorizontal?: number;
  className?: string;
}

const Title: React.FC<Props> = ({
  name,
  Size = "h3",
  marginVertical = 0,
  marginHorizontal = 0,
  className
}) => {
  return (
    <Size className={className} style={{ margin: `${marginVertical}px ${marginHorizontal}px` }}>
      {name}
    </Size>
  );
};

export default Title;
