import classNames from "classnames/bind";
import Burger from "components/Burger/Burger";
import { LogoTalantaNew } from "components/Icons/Icons";
import TopMenu from "components/TopMenu/TopMenu";
import React from "react";
import { NavLink } from "react-router-dom";
import usePathname from "src/hooks/usePathname";
import Styles from "./MainHeader.module.scss";

const cnb = classNames.bind(Styles);

type Props = {
  isHeaderMain?: boolean;
  className?: string;
  style?: React.CSSProperties;
};

const MainHeader: React.FC<Props> = ({ style, isHeaderMain, className }) => {
  const path = usePathname();

  return (
    <div style={style} className={cnb("header", isHeaderMain && "header-absolute", className)}>
      <div className={cnb("index-limiter")}>
        <div className={cnb("imageBlock")}>
          <NavLink to='/'>
            <LogoTalantaNew className={cnb("flashSvg")} />
          </NavLink>
        </div>

        <div className={cnb("menu")}>
          <div className={cnb("burger")}>
            <Burger currentPath={path} theme='light' page='main' />
          </div>
          <div className={cnb("topMenu")}>
            <TopMenu isNavMain />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainHeader;
