import {
  apiGetAllRoles,
  apiRefreshToken,
  apiResetPassword,
  apiUserLogin,
  apiUserLogout,
  apiUserRegistration
} from "api/login";
import { RoleType, Roles } from "reducers/LoginReducer/types";
import Queries from "src/hooks/queries/queriesList";
import { getTranslation } from "src/providers/I18nProvider";
import { createNotification } from "src/providers/NotificationsProvider";
import { queryClient } from "src/providers/ReactQueryProvider";
import { parseErrorApi } from "src/utils/errorApi";
import { formatRole } from "src/utils/roles";
import { actionClearStore } from "store/actions/app";
import {
  SET_ALL_ROLES,
  SET_AUTH,
  SET_LOGIN_LOADING,
  SET_ROLE,
  SET_TOKEN
} from "store/constants/login";
import { AppThunk } from "store/index";
import {
  ActionSetAllRolesType,
  ActionSetAuthType,
  ActionSetLoginLoadingType,
  ActionSetRoleType,
  ActionSetTokenType,
  LoginLoadingType
} from "./types";

export const actionSetAuth = (auth: boolean): ActionSetAuthType => ({
  type: SET_AUTH,
  auth
});

export const actionSetToken = (token: string, refresh_token: string): ActionSetTokenType => ({
  type: SET_TOKEN,
  token,
  refresh_token
});

export const actionLoginSetLoading = (loading: LoginLoadingType): ActionSetLoginLoadingType => ({
  type: SET_LOGIN_LOADING,
  loading
});

export const actionLoginSetRole = (role: Roles): ActionSetRoleType => ({
  type: SET_ROLE,
  role
});

export const actionLoginSetAllRoles = (roles: RoleType[]): ActionSetAllRolesType => ({
  type: SET_ALL_ROLES,
  roles
});

export const actionLogin = (login: string, password: string): AppThunk => {
  return async (dispatch) => {
    dispatch(actionLoginSetLoading("login"));

    queryClient.invalidateQueries(Queries.PROFILE_DATA);
    try {
      const response = await apiUserLogin(login, password);

      if (response?.data) {
        const { tokens, roles } = response.data.data;
        dispatch(actionSetToken(tokens.token, tokens.refresh_token));
        dispatch(actionLoginSetRole(formatRole(roles[0])));
      } else {
        dispatch(actionLoginSetLoading("none"));
        throw new Error("Error");
      }

      dispatch(actionSetAuth(true));
      queryClient.invalidateQueries(Queries.PROFILE_DATA);
    } catch (e: any) {
      if (e?.response?.status === 422 || e?.message?.includes("422")) {
        createNotification("error", getTranslation(`auth.loginOrPassInvalid`));
      } else if (e.response?.status) {
        createNotification("error", getTranslation(`response.${e.response?.status}`));
      } else {
        createNotification("error", getTranslation(`response.422`));
      }
    } finally {
      dispatch(actionLoginSetLoading("none"));
    }
  };
};

export const actionLogout = (logout: () => void): AppThunk => {
  return (dispatch) => {
    dispatch(actionLoginSetLoading("login"));
    apiUserLogout()
      .catch(() => {})
      .finally(() => {
        logout();
        dispatch(actionClearStore());
        queryClient.getQueryCache().clear();
      });
  };
};

export const actionRefreshToken = (token: string, refreshToken: string): AppThunk => {
  return (dispatch) => {
    apiRefreshToken(refreshToken)
      .then((r) => {
        const { data } = r.data;
        dispatch(actionSetToken(data.token, data.refresh_token));
      })
      .catch(() => {
        dispatch(actionClearStore());
      });
  };
};

export const actionRegister = (
  { email, password, repeatPassword, phone, full_name },
  success
): AppThunk => {
  return (dispatch) => {
    dispatch(actionLoginSetLoading("login"));
    apiUserRegistration({
      email,
      password,
      password_confirmation: repeatPassword,
      phone,
      full_name
    })
      .then((data) => success?.(data))
      .catch((error) => {
        const errors = error.response?.data?.errors.validation || {};

        Object.keys(errors).forEach((key) => {
          createNotification("error", errors[key]?.[0]);
        });
      })

      .finally(() => dispatch(actionLoginSetLoading("none")));
  };
};

export const actionResetPassword = (email: string, callBack: () => void = () => {}): AppThunk => {
  return () => {
    apiResetPassword(email)
      .then(() => {
        createNotification("success", "На ваш email высланы инструкции");
      })
      .catch((error) => {
        const errorApi = parseErrorApi(error);

        const errorMessage = errorApi?.email?.[0] || errorApi;

        createNotification("error", errorMessage);
      })
      .finally(() => {
        callBack();
      });
  };
};

export const actionGetAllRoles = (): AppThunk => {
  return (dispatch) => {
    apiGetAllRoles()
      .then((res) => {
        dispatch(actionLoginSetAllRoles(res.data.data));
      })
      .catch(() => {
        createNotification("error", getTranslation(`response.422`));
      });
  };
};
