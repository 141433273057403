import { useHistory, useLocation } from "react-router-dom";
import { useSearchParams } from "src/hooks/useSearchParams";

export const useBecomeModalAuthor = () => {
  const history = useHistory();
  const location = useLocation();

  const searchParams = useSearchParams();
  const isOpen = Boolean(searchParams.get("becomeAuthorModal"));

  const open = () => {
    const params = new URLSearchParams(location.search);
    params.append("becomeAuthorModal", "true");

    history.replace({ pathname: location.pathname, search: params.toString() });
  };

  const close = () => {
    const params = new URLSearchParams(location.search);
    params.delete("becomeAuthorModal");

    history.replace({ pathname: location.pathname, search: params.toString() });
  };

  return { open, close, isOpen };
};
