import { LoginActionTypes } from "store/actions/login/types";
import {
  SET_ALL_ROLES,
  SET_AUTH,
  SET_LOGIN_LOADING,
  SET_ROLE,
  SET_TOKEN
} from "../../constants/login";
import { LoginInitialStateType, Roles } from "./types";

const initialState: LoginInitialStateType = {
  isAuth: false,
  token: "",
  refresh_token: "",
  loading: "none",
  role: Roles.SCHOOL_OWNER,
  allRoles: []
};

const LoginReducer = (state = initialState, action: LoginActionTypes): LoginInitialStateType => {
  switch (action.type) {
    case SET_AUTH: {
      return {
        ...state,
        isAuth: action.auth
      };
    }
    case SET_TOKEN: {
      return {
        ...state,
        loading: "none",
        token: action.token,
        refresh_token: action.refresh_token
      };
    }
    case SET_LOGIN_LOADING: {
      return {
        ...state,
        loading: action.loading
      };
    }
    case SET_ROLE: {
      return {
        ...state,
        role: action.role
      };
    }
    case SET_ALL_ROLES: {
      return {
        ...state,
        allRoles: [...action.roles]
      };
    }
    default:
      return state;
  }
};

export default LoginReducer;
