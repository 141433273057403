/* eslint-disable no-param-reassign */
import { ConstructorActionTypes } from "store/actions/constructor/types";
import {
  ACTION_WITHOUT_MODULE,
  ACTION_WITH_LESSON,
  ACTION_WITH_LESSON_CONTENT,
  ACTION_WITH_MODULE,
  ACTION_WITH_TASK,
  ACTION_WITH_TASK_CONTENT,
  ADD_MODULES_TO_COURSE,
  COURSE_LOADING,
  SET_COMPLETED_BLOCK,
  SET_STATISTICS_START_DATE,
  SET_TASKS_EVALUATION
} from "../../constants/constructor";

import { ConstructorInitialStateType } from "./types";

const initialState: ConstructorInitialStateType = {
  courseList: []
};

const ConstructorReducer = (
  state = initialState,
  action: ConstructorActionTypes
): ConstructorInitialStateType => {
  switch (action.type) {
    case COURSE_LOADING:
      return {
        ...state,
        courseList: state.courseList.map((course) =>
          course.courseID === String(action.courseID)
            ? { ...course, isLoading: action.isLoading }
            : course
        )
      };
    case ADD_MODULES_TO_COURSE: {
      return {
        ...state,
        courseList: !state.courseList.find((item) => item.courseID === String(action.courseID))
          ? state.courseList.concat({
              courseID: String(action.courseID),
              moduleList: action.moduleList,
              isLoading: "none",
              wasEdited: false
            })
          : state.courseList.map((course) => {
              if (course.courseID === String(action.courseID)) {
                return {
                  courseID: String(action.courseID),
                  moduleList: action.moduleList,
                  isLoading: "none",
                  wasEdited: false
                };
              }
              return {
                courseID: course.courseID,
                moduleList: course.moduleList,
                isLoading: "none",
                wasEdited: false
              };
            })
      };
    }
    case ACTION_WITHOUT_MODULE: {
      return {
        courseList: state.courseList.concat({
          courseID: String(action.courseID),
          moduleList: [],
          isLoading: "none",
          wasEdited: false
        })
      };
    }
    case ACTION_WITH_MODULE: {
      switch (action.actionType) {
        case "create":
          return {
            ...state,
            courseList: state.courseList.map((course) => {
              if (course.courseID === String(action.courseID)) {
                return {
                  ...course,
                  wasEdited: true,
                  moduleList: course.moduleList.concat({
                    moduleID: action.moduleID,
                    moduleName: action.name,
                    lessons: [],
                    wasEdited: false,
                    wasCreated: true,
                    wasDeleted: false
                  })
                };
              }
              return course;
            })
          };

        case "flush":
          return {
            ...state,
            courseList: state.courseList.map((course) => {
              if (course.courseID === String(action.courseID)) {
                return {
                  ...course,
                  wasEdited: true,
                  moduleList: course.moduleList.filter(
                    (module) => module.moduleID !== action.moduleID
                  )
                };
              }
              return course;
            })
          };

        case "delete":
          return {
            ...state,
            courseList: state.courseList.map((course) => {
              if (course.courseID === String(action.courseID)) {
                return {
                  ...course,
                  wasEdited: true,
                  moduleList: course.moduleList.map((module) => {
                    if (module.moduleID === action.moduleID) {
                      return {
                        ...module,
                        moduleName: action.name,
                        wasDeleted: true
                      };
                    }
                    return module;
                  })
                };
              }
              return course;
            })
          };

        case "edit":
          return {
            ...state,
            courseList: state.courseList.map((course) => {
              if (course.courseID === String(action.courseID)) {
                return {
                  ...course,
                  wasEdited: true,
                  moduleList: course.moduleList.map((module) => {
                    if (module.moduleID === action.moduleID) {
                      // need check that id exists and course id is not uuid
                      const wasCreated = !action.moduleID || Number.isNaN(Number(action.moduleID));

                      return {
                        ...module,
                        moduleID: action.updateID ? String(action.updateID) : module.moduleID,
                        moduleName: action.updateID ? module.moduleName : action.name,
                        wasEdited: true,
                        wasCreated
                      };
                    }
                    return module;
                  })
                };
              }
              return course;
            })
          };
        default:
          break;
      }
      return state;
    }
    case ACTION_WITH_LESSON: {
      switch (action.actionType) {
        case "create":
          return {
            ...state,
            courseList: state.courseList.map((course) => {
              if (course.courseID === String(action.courseID)) {
                return {
                  ...course,
                  wasEdited: true,
                  moduleList: course.moduleList.map((module) => {
                    if (module.moduleID === action.moduleID) {
                      return {
                        ...module,
                        lessons: module.lessons.concat({
                          lessonID: action.lessonID,
                          lessonName: action.name,
                          lessonContent: [],
                          wasEdited: false,
                          wasCreated: true,
                          wasDeleted: false,
                          tasks: [],
                          isEndedByUser: false,
                          status: "not_started",
                          statistics: {
                            id: +action.lessonID,
                            course_id: +course.courseID,
                            section_id: +module.moduleID,
                            start_date: null,
                            end_date: null,
                            blocks: []
                          }
                        })
                      };
                    }
                    return module;
                  })
                };
              }
              return course;
            })
          };

        case "flush":
          return {
            ...state,
            courseList: state.courseList.map((course) => {
              if (course.courseID === String(action.courseID)) {
                return {
                  ...course,
                  wasEdited: true,
                  moduleList: course.moduleList.map((module) => {
                    if (module.moduleID === action.moduleID) {
                      return {
                        ...module,
                        lessons: module.lessons.filter(
                          (lesson) => lesson.lessonID !== action.lessonID
                        )
                      };
                    }
                    return module;
                  })
                };
              }
              return course;
            })
          };

        case "delete":
          return {
            ...state,
            courseList: state.courseList.map((course) => {
              if (course.courseID === String(action.courseID)) {
                return {
                  ...course,
                  wasEdited: true,
                  moduleList: course.moduleList.map((module) => {
                    if (module.moduleID === action.moduleID) {
                      return {
                        ...module,
                        lessons: module.lessons.map((lesson) => {
                          if (lesson.lessonID === action.lessonID) {
                            return {
                              ...lesson,
                              lessonName: action.name,
                              wasDeleted: true
                            };
                          }
                          return lesson;
                        })
                      };
                    }
                    return module;
                  })
                };
              }
              return course;
            })
          };

        case "edit":
          return {
            ...state,
            courseList: state.courseList.map((course) => {
              if (course.courseID === String(action.courseID)) {
                return {
                  ...course,
                  wasEdited: true,
                  moduleList: course.moduleList.map((module) => {
                    if (module.moduleID === action.moduleID) {
                      return {
                        ...module,
                        lessons: module.lessons.map((lesson) => {
                          if (lesson.lessonID === action.lessonID) {
                            return {
                              ...lesson,
                              lessonID: action.updateID ? String(action.updateID) : lesson.lessonID,
                              lessonName: action.updateID ? lesson.lessonName : action.name,
                              is_trial: action.is_trial ?? false,
                              wasEdited: true,
                              wasCreated: !!action.updateID
                            };
                          }
                          return lesson;
                        })
                      };
                    }
                    return module;
                  })
                };
              }
              return course;
            })
          };

        default:
          break;
      }
      return state;
    }
    case SET_COMPLETED_BLOCK: {
      const { courseID, moduleID, lessonID, blockID } = action.fields;

      return {
        ...state,
        courseList: state.courseList.map((course) => {
          if (course.courseID === String(courseID)) {
            return {
              ...course,
              wasEdited: true,
              moduleList: course.moduleList.map((module) => {
                if (module.moduleID === String(moduleID)) {
                  return {
                    ...module,
                    lessons: module.lessons.map((lesson) => {
                      if (lesson.lessonID === String(lessonID)) {
                        const statistics = { ...lesson.statistics };
                        const block = statistics.blocks.find((item) => item.id === blockID);

                        if (block) block.completed = true;
                        return {
                          ...lesson,
                          statistics
                        };
                      }
                      return lesson;
                    })
                  };
                }
                return module;
              })
            };
          }
          return course;
        })
      };
    }
    case SET_STATISTICS_START_DATE: {
      const { courseID, moduleID, lessonID } = action.fields;

      return {
        ...state,
        courseList: state.courseList.map((course) => {
          if (course.courseID === String(courseID)) {
            return {
              ...course,
              wasEdited: true,
              moduleList: course.moduleList.map((module) => {
                if (module.moduleID === String(moduleID)) {
                  return {
                    ...module,
                    lessons: module.lessons.map((lesson) => {
                      if (lesson.lessonID === String(lessonID)) {
                        const { statistics } = lesson;
                        return {
                          ...lesson,
                          statistics: {
                            ...statistics,
                            start_date: statistics.start_date
                              ? statistics.start_date
                              : new Date().toISOString()
                          }
                        };
                      }
                      return lesson;
                    })
                  };
                }
                return module;
              })
            };
          }
          return course;
        })
      };
    }
    case ACTION_WITH_LESSON_CONTENT: {
      switch (action.actionType) {
        case "add":
          return {
            ...state,
            courseList: state.courseList.map((course) => {
              if (course.courseID === String(action.courseID)) {
                return {
                  ...course,
                  wasEdited: true,
                  moduleList: course.moduleList.map((module) => {
                    if (module.moduleID === action.moduleID) {
                      return {
                        ...module,
                        lessons: module.lessons.map((lesson) => {
                          if (lesson.lessonID === action.lessonID) {
                            lesson.lessonContent.splice(
                              (action.indexOfMovableItem as number) + 1,
                              0,
                              action.lessonContent
                            );
                            return {
                              ...lesson,
                              wasEdited: true
                            };
                          }
                          return lesson;
                        })
                      };
                    }
                    return module;
                  })
                };
              }
              return course;
            })
          };

        case "delete":
          return {
            ...state,
            courseList: state.courseList.map((course) => {
              if (course.courseID === String(action.courseID)) {
                return {
                  ...course,
                  wasEdited: true,
                  moduleList: course.moduleList.map((module) => {
                    if (module.moduleID === action.moduleID) {
                      return {
                        ...module,
                        lessons: module.lessons.map((lesson) => {
                          if (lesson.lessonID === action.lessonID) {
                            return {
                              ...lesson,
                              wasEdited: true,
                              lessonContent: lesson.lessonContent.filter(
                                (contentBlock) =>
                                  contentBlock.contentBlockID !==
                                  action.lessonContent.contentBlockID
                              )
                            };
                          }
                          return lesson;
                        })
                      };
                    }
                    return module;
                  })
                };
              }
              return course;
            })
          };

        case "move":
          return {
            ...state,
            courseList: state.courseList.map((course) => {
              if (course.courseID === String(action.courseID)) {
                return {
                  ...course,
                  wasEdited: true,
                  moduleList: course.moduleList.map((module) => {
                    if (module.moduleID === action.moduleID) {
                      return {
                        ...module,
                        lessons: module.lessons.map((lesson) => {
                          if (lesson.lessonID === action.lessonID) {
                            [
                              lesson.lessonContent[action.indexOfMovableItem as number],
                              lesson.lessonContent[
                                (action.indexOfMovableItem as number) + (action.direction as number)
                              ]
                            ] = [
                              lesson.lessonContent[
                                (action.indexOfMovableItem as number) + (action.direction as number)
                              ],
                              lesson.lessonContent[action.indexOfMovableItem as number]
                            ];
                            return { ...lesson, wasEdited: true };
                          }
                          return lesson;
                        })
                      };
                    }
                    return module;
                  })
                };
              }
              return course;
            })
          };

        case "edit":
          return {
            ...state,
            courseList: state.courseList.map((course) => {
              if (course.courseID === String(action.courseID)) {
                return {
                  ...course,
                  wasEdited: true,
                  moduleList: course.moduleList.map((module) => {
                    if (module.moduleID === action.moduleID) {
                      return {
                        ...module,
                        lessons: module.lessons.map((lesson) => {
                          if (lesson.lessonID === action.lessonID) {
                            return {
                              ...lesson,
                              wasEdited: true,
                              lessonContent: lesson.lessonContent.map((contentBlock) => {
                                if (
                                  contentBlock.contentBlockID ===
                                  action.lessonContent.contentBlockID
                                ) {
                                  return { ...action.lessonContent };
                                }
                                return contentBlock;
                              })
                            };
                          }
                          return lesson;
                        })
                      };
                    }
                    return module;
                  })
                };
              }
              return course;
            })
          };

        default:
          break;
      }
      return state;
    }
    case ACTION_WITH_TASK: {
      switch (action.actionType) {
        case "create":
          return {
            ...state,
            courseList: state.courseList.map((course) => {
              if (course.courseID === String(action.courseID)) {
                return {
                  ...course,
                  wasEdited: true,
                  moduleList: course.moduleList.map((module) => {
                    if (module.moduleID === action.moduleID) {
                      return {
                        ...module,
                        lessons: module.lessons.map((lesson) => {
                          if (lesson.lessonID === action.lessonID) {
                            return {
                              ...lesson,
                              tasks: lesson.tasks.concat({
                                taskID: action.taskID,
                                name: action.name,
                                wasCreated: true,
                                wasEdited: false,
                                wasDeleted: false,
                                taskContent: []
                              })
                            };
                          }
                          return lesson;
                        })
                      };
                    }
                    return module;
                  })
                };
              }
              return course;
            })
          };

        case "edit":
          return {
            ...state,
            courseList: state.courseList.map((course) => {
              if (course.courseID === String(action.courseID)) {
                return {
                  ...course,
                  wasEdited: true,
                  moduleList: course.moduleList.map((module) => {
                    if (module.moduleID === action.moduleID) {
                      return {
                        ...module,
                        lessons: module.lessons.map((lesson) => {
                          if (lesson.lessonID === action.lessonID) {
                            return {
                              ...lesson,
                              tasks: lesson.tasks.map((task) => {
                                if (task.taskID === action.taskID) {
                                  return {
                                    ...task,
                                    taskID: action.updateID ? String(action.updateID) : task.taskID,
                                    wasEdited: true,
                                    wasCreated: !!action.updateID
                                  };
                                }
                                return task;
                              })
                            };
                          }
                          return lesson;
                        })
                      };
                    }
                    return module;
                  })
                };
              }
              return course;
            })
          };

        case "delete":
          return {
            ...state,
            courseList: state.courseList.map((course) => {
              if (course.courseID === String(action.courseID)) {
                return {
                  ...course,
                  wasEdited: true,
                  moduleList: course.moduleList.map((module) => {
                    if (module.moduleID === action.moduleID) {
                      return {
                        ...module,
                        lessons: module.lessons.map((lesson) => {
                          if (lesson.lessonID === action.lessonID) {
                            return {
                              ...lesson,
                              tasks: lesson.tasks.map((task) => {
                                if (task.taskID === action.taskID) {
                                  return {
                                    ...task,
                                    wasDeleted: true
                                  };
                                }
                                return task;
                              })
                            };
                          }
                          return lesson;
                        })
                      };
                    }
                    return module;
                  })
                };
              }
              return course;
            })
          };

        case "flush":
          return {
            ...state,
            courseList: state.courseList.map((course) => {
              if (course.courseID === String(action.courseID)) {
                return {
                  ...course,
                  wasEdited: true,
                  moduleList: course.moduleList.map((module) => {
                    if (module.moduleID === action.moduleID) {
                      return {
                        ...module,
                        lessons: module.lessons.map((lesson) => {
                          if (lesson.lessonID === action.lessonID) {
                            return {
                              ...lesson,
                              tasks: lesson.tasks.filter((task) => task.taskID !== action.taskID)
                            };
                          }
                          return lesson;
                        })
                      };
                    }
                    return module;
                  })
                };
              }
              return course;
            })
          };
        default:
          break;
      }
      return state;
    }
    case ACTION_WITH_TASK_CONTENT: {
      switch (action.actionType) {
        case "add":
          return {
            ...state,
            courseList: state.courseList.map((course) => {
              if (course.courseID === String(action.courseID)) {
                return {
                  ...course,
                  wasEdited: true,
                  moduleList: course.moduleList.map((module) => {
                    if (module.moduleID === action.moduleID) {
                      return {
                        ...module,
                        lessons: module.lessons.map((lesson) => {
                          if (lesson.lessonID === action.lessonID) {
                            return {
                              ...lesson,
                              tasks: lesson.tasks.map((task) => {
                                if (task.taskID === action.taskID) {
                                  task.taskContent.splice(
                                    (action.indexOfMovableItem as number) + 1,
                                    0,
                                    action.taskContent
                                  );
                                  return { ...task, wasEdited: true };
                                }
                                return task;
                              })
                            };
                          }
                          return lesson;
                        })
                      };
                    }
                    return module;
                  })
                };
              }
              return course;
            })
          };

        case "delete":
          return {
            ...state,
            courseList: state.courseList.map((course) => {
              if (course.courseID === String(action.courseID)) {
                return {
                  ...course,
                  wasEdited: true,
                  moduleList: course.moduleList.map((module) => {
                    if (module.moduleID === action.moduleID) {
                      return {
                        ...module,
                        lessons: module.lessons.map((lesson) => {
                          if (lesson.lessonID === action.lessonID) {
                            return {
                              ...lesson,
                              tasks: lesson.tasks.map((task) => {
                                if (task.taskID === action.taskID) {
                                  return {
                                    ...task,
                                    wasEdited: true,
                                    taskContent: task.taskContent.filter(
                                      (contentBlock) =>
                                        contentBlock.contentBlockID !==
                                        action.taskContent.contentBlockID
                                    )
                                  };
                                }
                                return task;
                              })
                            };
                          }
                          return lesson;
                        })
                      };
                    }
                    return module;
                  })
                };
              }
              return course;
            })
          };

        case "move":
          return {
            ...state,
            courseList: state.courseList.map((course) => {
              if (course.courseID === String(action.courseID)) {
                return {
                  ...course,
                  wasEdited: true,
                  moduleList: course.moduleList.map((module) => {
                    if (module.moduleID === action.moduleID) {
                      return {
                        ...module,
                        lessons: module.lessons.map((lesson) => {
                          if (lesson.lessonID === action.lessonID) {
                            return {
                              ...lesson,
                              tasks: lesson.tasks.map((task) => {
                                if (task.taskID === action.taskID) {
                                  [
                                    task.taskContent[action.indexOfMovableItem as number],
                                    task.taskContent[
                                      (action.indexOfMovableItem as number) +
                                        (action.direction as number)
                                    ]
                                  ] = [
                                    task.taskContent[
                                      (action.indexOfMovableItem as number) +
                                        (action.direction as number)
                                    ],
                                    task.taskContent[action.indexOfMovableItem as number]
                                  ];
                                  return { ...task, wasEdited: true };
                                }
                                return task;
                              })
                            };
                          }
                          return lesson;
                        })
                      };
                    }
                    return module;
                  })
                };
              }
              return course;
            })
          };

        case "edit":
          return {
            ...state,
            courseList: state.courseList.map((course) => {
              if (course.courseID === String(action.courseID)) {
                return {
                  ...course,
                  wasEdited: true,
                  moduleList: course.moduleList.map((module) => {
                    if (module.moduleID === action.moduleID) {
                      return {
                        ...module,
                        lessons: module.lessons.map((lesson) => {
                          if (lesson.lessonID === action.lessonID) {
                            return {
                              ...lesson,
                              tasks: lesson.tasks.map((task) => {
                                if (task.taskID === action.taskID) {
                                  return {
                                    ...task,
                                    wasEdited: true,
                                    taskContent: task.taskContent.map((contentBlock) => {
                                      if (
                                        contentBlock.contentBlockID ===
                                        action.taskContent.contentBlockID
                                      ) {
                                        return action.taskContent;
                                      }

                                      return contentBlock;
                                    })
                                  };
                                }
                                return task;
                              })
                            };
                          }
                          return lesson;
                        })
                      };
                    }
                    return module;
                  })
                };
              }
              return course;
            })
          };
        default:
          break;
      }
      return state;
    }
    case SET_TASKS_EVALUATION: {
      const { courseID, moduleID, lessonID, blocks } = action;
      const ids = blocks.map(({ content_block_id }) => content_block_id);

      return {
        ...state,
        courseList: state.courseList.map((course) => {
          if (course.courseID === String(courseID)) {
            return {
              ...course,
              wasEdited: true,
              moduleList: course.moduleList.map((module) => {
                if (module.moduleID === moduleID) {
                  return {
                    ...module,
                    lessons: module.lessons.map((lesson) => {
                      if (lesson.lessonID === lessonID) {
                        return {
                          ...lesson,
                          tasks: lesson.tasks.map((task) => {
                            return {
                              ...task,
                              taskContent: task.taskContent.map((block) => {
                                if (ids.includes(+block.contentBlockID)) {
                                  const evaluation = blocks.find(
                                    (item) => item.content_block_id === +block.contentBlockID
                                  )?.evaluations;

                                  return {
                                    ...block,
                                    status: "needsWork",
                                    evaluation: {
                                      ...block.evaluation,
                                      ...evaluation
                                    }
                                  };
                                }
                                return block;
                              })
                            };
                          })
                        };
                      }
                      return lesson;
                    })
                  };
                }
                return module;
              })
            };
          }
          return course;
        }) as any
      };
    }

    default:
      return state;
  }
};

export default ConstructorReducer;
