import classNames from "classnames/bind";
import { useBecomeModalAuthor } from "components/ModalBecomeAuthor/lib";
import React from "react";
import { NavLink } from "react-router-dom";
import { NavItem } from "../config";
import { ThemeType } from "./BurgerHeader";
import Styles from "./BurgerMainContent.module.scss";

const cnb = classNames.bind(Styles);

interface Props {
  theme: ThemeType;
  items: NavItem[];
  className?: string;
  onClickItem: () => void;
}

const BurgerMainContent: React.FC<Props> = ({ items, className, onClickItem }) => {
  const { open } = useBecomeModalAuthor();

  const onClick = (event: React.MouseEvent, link: string) => {
    if (link === "#author") {
      event.preventDefault();

      open();
    }

    onClickItem();
  };

  return (
    <div className={cnb("topMenu", className)}>
      {items
        .filter(({ button }) => !button)
        .map(({ title, link, button, isExternalLink }) => {
          return (
            <>
              {isExternalLink ? (
                <a href={link} key={`${title}-${link}`} className={cnb("link")}>
                  {title}
                </a>
              ) : (
                <NavLink
                  onClick={(event) => onClick(event, link)}
                  to={link}
                  className={cnb("link", { button })}
                  key={title}
                >
                  {title}
                </NavLink>
              )}
            </>
          );
        })}
    </div>
  );
};

export default BurgerMainContent;
