import { ProfileType } from "reducers/ProfileReducer/types";
import { ProfileActionTypes } from "store/actions/profile/types";
import SET_PROFILE from "store/constants/profile";

const initialState: ProfileType = {
  name: "",
  surname: "",
  email: "",
  phone: "",
  work: "",
  date_of_birth: "",
  profile_id: 0,
  user_id: 0,
  profilePicture: ""
};

const ProfileReducer = (state = initialState, action: ProfileActionTypes): ProfileType => {
  switch (action.type) {
    case SET_PROFILE: {
      const { fields } = action;
      return {
        ...state,
        ...fields
      };
    }
    default:
      return state;
  }
};

export default ProfileReducer;
