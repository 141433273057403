import { CourseInitialState } from "reducers/CourseReducer/types";
import { createItem, markForDelete, restoreItem } from "src/utils/courseReducerUtils";
import { CourseActionTypes } from "store/actions/course/types";
import CourseConstants from "store/constants/course";
import { v4 as uuidv4 } from "uuid";

const initialState: CourseInitialState = {
  courseList: []
};

const CoursesReducer = (state = initialState, action: CourseActionTypes): CourseInitialState => {
  const courseIsExist = state.courseList.filter((course) => {
    return String(course.courseID) === String(action.courseID);
  }).length;

  const maxAdvantages = 3;

  if (action.type === CourseConstants.SET_COURSE) {
    if (action.action === "remove") {
      return {
        courseList: state.courseList.filter((item) => {
          return String(item.courseID) !== String(action.courseID);
        })
      };
    }
    if (!courseIsExist) {
      return {
        ...state,
        courseList: state.courseList.concat({
          courseID: String(action.courseID),
          hash: "",
          created: false,
          need_to_be_paid: false,
          page: {
            about: {
              general: {
                name: {
                  value: ""
                },
                summary: "",
                lastLesson: {
                  lessonID: "",
                  lessonName: "",
                  moduleID: "",
                  lessonIndex: 1
                },
                category: {
                  id: 0,
                  name: ""
                },
                advantages: [
                  {
                    id: uuidv4(),
                    description: ""
                  },
                  {
                    id: uuidv4(),
                    description: ""
                  },
                  {
                    id: uuidv4(),
                    description: ""
                  }
                ],
                testimonials: [],
                students_count: 0,
                has_testimonial_by_auth_user: false
              },
              additional: {
                price: {
                  value: "0"
                },
                old_price: {
                  value: "0"
                },
                description: {
                  value: ""
                },
                completionDoc: {
                  id: 0,
                  name: ""
                },
                practiceType: {
                  id: 0,
                  name: ""
                },
                additionalDescription: ""
              },
              style: {
                back: "",
                button_color: "",
                button_font_color: "",
                cover_font_color: "",
                cover: "",
                font_color: "",
                id: 1,
                is_visible: true,
                name: ""
              },
              teachers: [],
              programs: [],
              results: [],
              consumers: [],
              learns: [],
              author: "",
              completedLessonsAmount: 0,
              lessonsAmount: 0,
              progress: 0
            },
            statistics: {
              courses: [],
              students: [],
              lessons: []
            },
            students: [],
            settings: {
              actions: {
                accessEnable: true,
                commentsEnable: false,
                consecutiveAppearance: false,
                longreadContent: false,
                prohibitReplay: false,
                showClassmates: true
              },
              payments: [],
              accesses: {
                available: true,
                accessByEmail: false,
                teachers: [],
                link: "",
                maxCount: "5",
                isSetMaxCount: false
              }
            }
          }
        })
      };
    }
  }

  return <CourseInitialState>{
    courseList: state.courseList.map((course) => {
      if (String(action.courseID) && String(course.courseID) === String(action.courseID)) {
        switch (action.type) {
          case CourseConstants.SET_SELECTED_SCHOOL_ID: {
            return {
              ...course,
              selectedSchoolID: action.fields.schoolID
            };
          }
          case CourseConstants.SET_ABOUT_GENERAL: {
            const advArrLength = maxAdvantages - (action.fields?.advantages?.length ?? 0);

            return {
              ...course,
              hash: action.hash,
              school: action.school,
              need_to_be_paid: action.fields?.need_to_be_paid,
              page: {
                ...course.page,
                about: {
                  ...course.page.about,
                  general: {
                    ...course.page.about.general,
                    lastLesson: action.fields.lastLesson,
                    name: {
                      ...action.fields.name,
                      value:
                        action.fields.name !== undefined
                          ? action.fields.name.value
                          : course.page.about.general.name.value
                    },

                    summary:
                      action.fields.summary !== undefined
                        ? action.fields.summary
                        : course.page.about.general.summary,

                    testimonials: action.fields.testimonials || [],
                    students_count:
                      action.fields.students_count ?? course.page.about.general.students_count,
                    has_testimonial_by_auth_user:
                      action.fields.has_testimonial_by_auth_user ??
                      course.page.about.general.has_testimonial_by_auth_user,
                    advantages: [
                      ...(action.fields.advantages ?? []),
                      ...(advArrLength > 0
                        ? Array.from(Array(advArrLength), () => {
                            return {
                              id: uuidv4(),
                              description: ""
                            };
                          })
                        : [])
                    ],
                    category: {
                      ...(action.fields.category ?? {}),
                      id:
                        action.fields.category && action.fields.category.id !== undefined
                          ? action.fields.category.id
                          : course.page.about.general.category.id,
                      name:
                        action.fields.category && action.fields.category.name !== undefined
                          ? action.fields.category.name
                          : course.page.about.general.category.name
                    }
                  }
                }
              }
            };
          }

          case CourseConstants.SET_ABOUT_ADDITIONAL: {
            const { additional } = course.page.about;

            return {
              ...course,
              page: {
                ...course.page,
                about: {
                  ...course.page.about,
                  additional: {
                    price: {
                      ...action.fields.price
                    },
                    old_price: {
                      ...action.fields.old_price
                    },
                    description: {
                      ...action.fields.description,
                      value:
                        action.fields.description !== undefined
                          ? action.fields.description?.value
                          : additional.description?.value
                    },
                    completionDoc: {
                      ...action.fields.completionDoc,
                      id:
                        action.fields.completionDoc?.id !== undefined
                          ? action.fields.completionDoc.id
                          : additional.completionDoc.id,
                      name:
                        action.fields.completionDoc?.name !== undefined
                          ? action.fields.completionDoc.name
                          : additional.completionDoc.name
                    },
                    practiceType: {
                      ...action.fields.practiceType,
                      id:
                        action.fields.practiceType?.id !== undefined
                          ? action.fields.practiceType.id
                          : additional.practiceType.id,
                      name:
                        action.fields.practiceType?.name !== undefined
                          ? action.fields.practiceType.name
                          : additional.practiceType.name
                    },
                    additionalDescription: action.fields.additionalDescription ?? ""
                  }
                }
              }
            };
          }
          case CourseConstants.SET_ABOUT_PROGRAMS: {
            switch (action.actionType) {
              case "update":
                return {
                  ...course,
                  page: {
                    ...course.page,
                    about: {
                      ...course.page.about,
                      programs: course.page.about.programs.map((item) => {
                        if (item.id === action.fields.id || item.id === "") {
                          return {
                            ...action.fields,
                            name: action.fields.name !== undefined ? action.fields.name : item.name,
                            description:
                              action.fields.description !== undefined
                                ? action.fields.description
                                : item.description
                          };
                        }
                        return { ...item };
                      })
                    }
                  }
                };
              case "create": {
                return {
                  ...course,
                  page: {
                    ...course.page,
                    about: {
                      ...course.page.about,
                      programs: createItem(
                        course.page.about.programs,
                        action.fields,
                        course.courseID === "new"
                      )
                    }
                  }
                };
              }
              case "markForDelete":
              case "delete": {
                return {
                  ...course,
                  page: {
                    ...course.page,
                    about: {
                      ...course.page.about,
                      programs: markForDelete(
                        course.page.about.programs,
                        action.fields.id,
                        action.actionType === "delete"
                      )
                    }
                  }
                };
              }
              case "restore": {
                return {
                  ...course,
                  page: {
                    ...course.page,
                    about: {
                      ...course.page.about,
                      programs: restoreItem(course.page.about.programs, action.fields.id)
                    }
                  }
                };
              }

              default:
                return {
                  ...course
                };
            }
          }
          case CourseConstants.SET_ABOUT_RESULTS: {
            switch (action.actionType) {
              case "update":
                return {
                  ...course,
                  page: {
                    ...course.page,
                    about: {
                      ...course.page.about,
                      results: course.page.about.results.map((item) => {
                        if (item.id === action.fields.id || item.id === "") {
                          return {
                            ...action.fields,
                            name: action.fields.name !== undefined ? action.fields.name : item.name,
                            description:
                              action.fields.description !== undefined
                                ? action.fields.description
                                : item.description,
                            color:
                              action.fields.color !== undefined ? action.fields.color : item.color,
                            position:
                              action.fields.position !== undefined
                                ? action.fields.position
                                : item.position
                          };
                        }
                        return { ...item };
                      })
                    }
                  }
                };
              case "create": {
                return {
                  ...course,
                  page: {
                    ...course.page,
                    about: {
                      ...course.page.about,
                      results: createItem(
                        course.page.about.results,
                        action.fields,
                        course.courseID === "new"
                      )
                    }
                  }
                };
              }
              case "markForDelete":
              case "delete": {
                return {
                  ...course,
                  page: {
                    ...course.page,
                    about: {
                      ...course.page.about,
                      results: markForDelete(
                        course.page.about.results,
                        action.fields.id,
                        action.actionType === "delete"
                      )
                    }
                  }
                };
              }
              case "restore": {
                return {
                  ...course,
                  page: {
                    ...course.page,
                    about: {
                      ...course.page.about,
                      results: restoreItem(course.page.about.results, action.fields.id)
                    }
                  }
                };
              }
              case "clear": {
                return {
                  ...course,
                  page: {
                    ...course.page,
                    about: {
                      ...course.page.about,
                      results: []
                    }
                  }
                };
              }

              default:
                return {
                  ...course
                };
            }
          }
          case CourseConstants.SET_ABOUT_CONSUMERS: {
            switch (action.actionType) {
              case "update":
                return {
                  ...course,
                  page: {
                    ...course.page,
                    about: {
                      ...course.page.about,
                      consumers: course.page.about.consumers.map((item) => {
                        if (item.id === action.fields.id || item.id === "") {
                          return {
                            ...action.fields,
                            description:
                              action.fields.description !== undefined
                                ? action.fields.description
                                : item.description,
                            image:
                              action.fields.image !== undefined ? action.fields.image : item.image
                          };
                        }
                        return { ...item };
                      })
                    }
                  }
                };
              case "create": {
                return {
                  ...course,
                  page: {
                    ...course.page,
                    about: {
                      ...course.page.about,
                      consumers: createItem(
                        course.page.about.consumers,
                        action.fields,
                        course.courseID === "new"
                      )
                    }
                  }
                };
              }
              case "markForDelete":
              case "delete": {
                return {
                  ...course,
                  page: {
                    ...course.page,
                    about: {
                      ...course.page.about,
                      consumers: markForDelete(
                        course.page.about.consumers,
                        action.fields.id ?? 0,
                        action.actionType === "delete"
                      )
                    }
                  }
                };
              }
              case "restore": {
                return {
                  ...course,
                  page: {
                    ...course.page,
                    about: {
                      ...course.page.about,
                      consumers: restoreItem(course.page.about.consumers, action.fields.id ?? 0)
                    }
                  }
                };
              }
              case "clear": {
                return {
                  ...course,
                  page: {
                    ...course.page,
                    about: {
                      ...course.page.about,
                      consumers: []
                    }
                  }
                };
              }

              default:
                return {
                  ...course
                };
            }
          }
          case CourseConstants.SET_ABOUT_LEARNS: {
            switch (action.actionType) {
              case "update":
                return {
                  ...course,
                  page: {
                    ...course.page,
                    about: {
                      ...course.page.about,
                      learns: course.page.about.learns.map((item) => {
                        if (item.id === action.fields.id || item.id === "") {
                          return {
                            ...action.fields,
                            description:
                              action.fields.description !== undefined
                                ? action.fields.description
                                : item.description,
                            image:
                              action.fields.image !== undefined ? action.fields.image : item.image
                          };
                        }
                        return { ...item };
                      })
                    }
                  }
                };
              case "create": {
                return {
                  ...course,
                  page: {
                    ...course.page,
                    about: {
                      ...course.page.about,
                      learns: createItem(
                        course.page.about.learns,
                        action.fields,
                        course.courseID === "new"
                      )
                    }
                  }
                };
              }
              case "markForDelete":
              case "delete": {
                return {
                  ...course,
                  page: {
                    ...course.page,
                    about: {
                      ...course.page.about,
                      learns: markForDelete(
                        course.page.about.learns,
                        action.fields.id ?? 0,
                        action.actionType === "delete"
                      )
                    }
                  }
                };
              }
              case "restore": {
                return {
                  ...course,
                  page: {
                    ...course.page,
                    about: {
                      ...course.page.about,
                      learns: restoreItem(course.page.about.learns, action.fields.id ?? 0)
                    }
                  }
                };
              }
              case "clear": {
                return {
                  ...course,
                  page: {
                    ...course.page,
                    about: {
                      ...course.page.about,
                      learns: []
                    }
                  }
                };
              }

              default:
                return {
                  ...course
                };
            }
          }
          case CourseConstants.SET_ABOUT_STYLE: {
            return {
              ...course,
              page: {
                ...course.page,
                about: {
                  ...course.page.about,
                  style: action.fields
                }
              }
            };
          }
          case CourseConstants.SET_ABOUT_AUTHOR: {
            return {
              ...course,
              page: {
                ...course.page,
                about: {
                  ...course.page.about,
                  author: action.fields.authorName
                }
              }
            };
          }
          case CourseConstants.SET_ABOUT_COURSE_PROGRESS: {
            return {
              ...course,
              page: {
                ...course.page,
                about: {
                  ...course.page.about,
                  ...action.fields
                }
              }
            };
          }
          case CourseConstants.SET_ABOUT_TEACHERS: {
            switch (action.actionType) {
              case "update":
                return {
                  ...course,
                  page: {
                    ...course.page,
                    about: {
                      ...course.page.about,
                      teachers: course.page.about.teachers.map((item) => {
                        if (item && (item.id === action.fields.id || item.id === "")) {
                          return {
                            ...action.fields,
                            name: action.fields.name !== undefined ? action.fields.name : item.name,
                            image:
                              action.fields.image !== undefined ? action.fields.image : item.image,
                            description:
                              action.fields.description !== undefined
                                ? action.fields.description
                                : item.description
                          };
                        }
                        return { ...item };
                      })
                    }
                  }
                };
              case "create": {
                return {
                  ...course,
                  page: {
                    ...course.page,
                    about: {
                      ...course.page.about,
                      teachers: createItem(
                        course.page.about.teachers,
                        action.fields,
                        course.courseID === "new"
                      )
                    }
                  }
                };
              }
              case "markForDelete":
              case "delete":
                return {
                  ...course,
                  page: {
                    ...course.page,
                    about: {
                      ...course.page.about,
                      teachers: markForDelete(
                        course.page.about.teachers,
                        action.fields.id ?? 0,
                        action.actionType === "delete"
                      )
                    }
                  }
                };
              case "restore": {
                return {
                  ...course,
                  page: {
                    ...course.page,
                    about: {
                      ...course.page.about,
                      teachers: restoreItem(course.page.about.teachers, action.fields.id ?? 0)
                    }
                  }
                };
              }
              default:
                return course;
            }
          }
          case CourseConstants.SET_STUDENTS:
            return {
              ...course,
              page: {
                ...course.page,
                students: action.fields
              }
            };
          case CourseConstants.SET_SETTINGS_ACCESS:
            return {
              ...course,
              page: {
                ...course.page,
                settings: {
                  ...course.page.settings,
                  accesses: {
                    available: action.fields.available ?? course.page.settings.accesses?.available,
                    accessByEmail:
                      action.fields.accessByEmail ?? course.page.settings.accesses?.accessByEmail,
                    isSetMaxCount:
                      action.fields.isSetMaxCount ?? course.page.settings.accesses?.isSetMaxCount,
                    link:
                      action.fields.link !== undefined
                        ? action.fields.link
                        : course.page.settings.accesses?.link,
                    maxCount:
                      action.fields.maxCount !== undefined
                        ? action.fields.maxCount.replace(/[^\d+]/, "")
                        : course.page.settings.accesses?.maxCount?.replace(/[^\d+]/, ""),
                    teachers:
                      action.fields.teachers !== undefined
                        ? action.fields.teachers
                        : course.page.settings.accesses?.teachers
                  }
                }
              }
            };
          case CourseConstants.SET_SETTINGS_PAYMENT:
            return {
              ...course,
              page: {
                ...course.page,
                settings: {
                  ...course.page.settings,
                  payments: course.page.settings.payments?.map((p) =>
                    p.id === action.fields.id
                      ? { ...p, selected: action.fields.selected }
                      : { ...p }
                  )
                }
              }
            };
          case CourseConstants.SET_SETTINGS_ACTION:
            return {
              ...course,
              page: {
                ...course.page,
                settings: {
                  ...course.page.settings,
                  actions: {
                    accessEnable:
                      action.fields.accessEnable !== undefined
                        ? action.fields.accessEnable
                        : course.page.settings.actions?.accessEnable,
                    commentsEnable:
                      action.fields.commentsEnable !== undefined
                        ? action.fields.commentsEnable
                        : course.page.settings.actions?.commentsEnable,
                    consecutiveAppearance:
                      action.fields.consecutiveAppearance !== undefined
                        ? action.fields.consecutiveAppearance
                        : course.page.settings.actions?.consecutiveAppearance,
                    longreadContent:
                      action.fields.longreadContent !== undefined
                        ? action.fields.longreadContent
                        : course.page.settings.actions?.longreadContent,
                    prohibitReplay:
                      action.fields.prohibitReplay !== undefined
                        ? action.fields.prohibitReplay
                        : course.page.settings.actions?.prohibitReplay,
                    showClassmates:
                      action.fields.showClassmates !== undefined
                        ? action.fields.showClassmates
                        : course.page.settings.actions?.showClassmates
                  }
                }
              }
            };
          case CourseConstants.SET_STATS_TABLE_LESSONS:
            return {
              ...course,
              page: {
                ...course.page,
                statistics: {
                  ...course.page.statistics,
                  lessons: [...action.fields]
                }
              }
            };
          case CourseConstants.SET_STATS_TABLE_COURSE:
            return {
              ...course,
              page: {
                ...course.page,
                statistics: {
                  ...course.page.statistics,
                  courses: [...action.fields]
                }
              }
            };
          case CourseConstants.SET_STATS_TABLE_STUDENTS:
            return {
              ...course,
              page: {
                ...course.page,
                statistics: {
                  ...course.page.statistics,
                  students: [...action.fields]
                }
              }
            };

          default:
            return { ...course };
        }
      }

      return { ...course };
    })
  };
};

export default CoursesReducer;
