import { ErrorFallback } from "components/ErrorFallback";
import Loader from "components/Loader/Loader";
import { ModalBecomeAuthor } from "components/ModalBecomeAuthor/ModalBecomeAuthor";
import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import { Roles } from "reducers/LoginReducer/types";
import RouteWithLayout from "src/routing/RouteWithLayout";
import { PublicLayout } from "src/shared/layouts/PublicLayout";
import { ErrorBoundary } from "react-error-boundary";

interface Props {
  isAuth: boolean;
  role: Roles | undefined;
}

/* Public */
const HomePage = React.lazy(
  () => import(/* webpackChunkName: "HomePage" */ "scene/public/HomePage/HomePage")
);
const CourseList = React.lazy(
  () => import(/* webpackChunkName: "CourseList" */ "scene/public/CourseList/CourseList")
);
const SchoolPreview = React.lazy(
  () => import(/* webpackChunkName: "SchoolPreview" */ "scene/public/SchoolPreview/SchoolPreview")
);
const SchoolsPage = React.lazy(
  () => import(/* webpackChunkName: "Schools" */ "scene/public/Schools/Schools")
);
const LegalInformation = React.lazy(
  () =>
    import(
      /* webpackChunkName: "LegalInformation" */ "scene/public/LegalInformation/LegalInformation"
    )
);
const Requisites = React.lazy(
  () => import(/* webpackChunkName: "Requisites" */ "scene/public/Requisites/Requisites")
);
const Contacts = React.lazy(
  () => import(/* webpackChunkName: "Contacts" */ "scene/public/Contacts/Contacts")
);
/* END Public */

const NotFound = React.lazy(
  () => import(/* webpackChunkName: "NotFound" */ "components/NotFound/NotFound")
);

/* Private */
const MyCourses = React.lazy(
  () => import(/* webpackChunkName: "MyCourses" */ "scene/private/Courses/MyCourses")
);
const CreatedCourses = React.lazy(
  () => import(/* webpackChunkName: "CreatedCourses" */ "scene/private/Courses/CreatedCourses")
);
/* Component for creating a course for a user with creator rights */
const CreateCourses = React.lazy(
  () =>
    import(
      /* webpackChunkName: "CreateCourses" */ "scene/private/Courses/CreateCourses/CreateCourses"
    )
);
/* Component for viewing a course for students */
const ViewCourses = React.lazy(
  () =>
    import(/* webpackChunkName: "ViewCourses" */ "scene/private/Courses/ViewCourses/ViewCourses")
);
/* Component for checking a course for teachers */
const Profile = React.lazy(
  () => import(/* webpackChunkName: "Profile" */ "scene/private/Profile/Profile")
);
const AvailableCourses = React.lazy(
  () => import(/* webpackChunkName: "AvailableCourses" */ "scene/AvailableCourses/AvailableCourses")
);
const CheckTasks = React.lazy(
  () => import(/* webpackChunkName: "CheckTasks" */ "scene/CheckTasks/CheckTasks")
);

const Preview = React.lazy(
  () => import(/* webpackChunkName: "Preview" */ "scene/private/Preview/Preview")
);
const PreviewLessons = React.lazy(
  () =>
    import(/* webpackChunkName: "PreviewLessons" */ "scene/private/PreviewLessons/PreviewLessons")
);
const UsersStatistics = React.lazy(
  () =>
    import(
      /* webpackChunkName: "UsersStatistics" */ "scene/private/UsersStatistics/UsersStatistics"
    )
);
const Students = React.lazy(
  () => import(/* webpackChunkName: "Page" */ "scene/private/Students/Page")
);
const PageInLongreadFormat = React.lazy(
  () =>
    import(
      /* webpackChunkName: "PageInLongreadFormat" */ "scene/private/PageInLongreadFormat/PageInLongreadFormat"
    )
);
/* END Private */

/* Auth */
const Login = React.lazy(() => import(/* webpackChunkName: "Login" */ "scene/auth/Login"));
const Reset = React.lazy(() => import(/* webpackChunkName: "Reset" */ "scene/auth/Reset"));
const Registration = React.lazy(
  () => import(/* webpackChunkName: "Registration" */ "scene/auth/Registration")
);
const Forgot = React.lazy(() => import(/* webpackChunkName: "Forgot" */ "scene/auth/Forgot"));
/* END Auth */

const Routes: React.FC<Props> = ({ isAuth, role }): React.ReactElement => {
  return (
    <>
      <ModalBecomeAuthor />

      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Suspense
          fallback={
            <div className='pageLoader'>
              <Loader />
            </div>
          }
        >
          <Switch>
            <PublicLayout exact path='/'>
              <HomePage />
            </PublicLayout>

            <PublicLayout exact path='/schools'>
              <SchoolsPage />
            </PublicLayout>

            <PublicLayout exact path='/legalInformation'>
              <LegalInformation />
            </PublicLayout>

            <PublicLayout exact path='/requisites'>
              <Requisites />
            </PublicLayout>

            <PublicLayout exact path='/contacts'>
              <Contacts />
            </PublicLayout>

            <PublicLayout exact path='/course'>
              <CourseList />
            </PublicLayout>

            <RouteWithLayout
              exact
              path='/school/:schoolID'
              Component={<SchoolPreview />}
              pageClassName='index'
              isPrivate={false}
              withPageContainer={false}
              showLeftSide={false}
            />

            <RouteWithLayout
              exact
              path='/login'
              Component={<Login isAuth={isAuth} />}
              isAuth={isAuth}
              isPrivate={false}
              pageClassName='auth'
              withPageContainer={false}
              showLeftSide={false}
              showFooter={false}
            />
            <RouteWithLayout
              exact
              path='/password/reset'
              Component={<Reset isAuth={isAuth} />}
              isAuth={isAuth}
              isPrivate={false}
              pageClassName='auth'
              withPageContainer={false}
              showLeftSide={false}
              showFooter={false}
            />
            <RouteWithLayout
              exact
              path='/register'
              Component={<Registration isAuth={isAuth} />}
              isAuth={isAuth}
              isPrivate={false}
              pageClassName='auth'
              withPageContainer={false}
              showFooter={false}
              showLeftSide={false}
            />
            <RouteWithLayout
              exact
              path='/forgot'
              Component={<Forgot isAuth={isAuth} />}
              isAuth={isAuth}
              isPrivate={false}
              pageClassName='auth'
              withPageContainer={false}
              showFooter={false}
              showLeftSide={false}
            />
            <RouteWithLayout
              exact
              path='/myCourses'
              Component={<MyCourses />}
              isAuth={isAuth}
              allowedRoles={[
                Roles.SCHOOL_OWNER,
                Roles.STUDENT,
                Roles.TEACHER,
                Roles.MANAGER,
                Roles.ADMIN
              ]}
              userRole={role}
            />
            <RouteWithLayout
              exact
              path='/myCourses/:courseID'
              Component={<ViewCourses />}
              isAuth={isAuth}
              allowedRoles={[
                Roles.SCHOOL_OWNER,
                Roles.STUDENT,
                Roles.TEACHER,
                Roles.MANAGER,
                Roles.ADMIN
              ]}
              userRole={role}
              leftSideIsMini
            />
            <RouteWithLayout
              exact
              path='/myCourses/:courseID/:tab'
              Component={<ViewCourses />}
              isAuth={isAuth}
              allowedRoles={[
                Roles.SCHOOL_OWNER,
                Roles.STUDENT,
                Roles.TEACHER,
                Roles.MANAGER,
                Roles.ADMIN
              ]}
              userRole={role}
              leftSideIsMini
            />
            <RouteWithLayout
              exact
              path='/myCourses/:courseID/:tab/:moduleID'
              Component={<ViewCourses />}
              isAuth={isAuth}
              allowedRoles={[
                Roles.SCHOOL_OWNER,
                Roles.STUDENT,
                Roles.TEACHER,
                Roles.MANAGER,
                Roles.ADMIN
              ]}
              userRole={role}
              leftSideIsMini
            />
            <RouteWithLayout
              exact
              path='/myCourses/:courseID/:tab/:moduleID/:lessonID'
              Component={<ViewCourses />}
              isAuth={isAuth}
              allowedRoles={[
                Roles.SCHOOL_OWNER,
                Roles.STUDENT,
                Roles.TEACHER,
                Roles.MANAGER,
                Roles.ADMIN
              ]}
              userRole={role}
              leftSideIsMini
            />
            <RouteWithLayout
              exact
              path='/createdCourses'
              Component={<CreatedCourses />}
              isAuth={isAuth}
              allowedRoles={[Roles.SCHOOL_OWNER, Roles.TEACHER, Roles.MANAGER, Roles.ADMIN]}
              userRole={role}
            />
            <RouteWithLayout
              exact
              path='/createdCourses/:courseID'
              Component={<CreateCourses />}
              isAuth={isAuth}
              allowedRoles={[Roles.SCHOOL_OWNER, Roles.TEACHER, Roles.MANAGER, Roles.ADMIN]}
              userRole={role}
              leftSideIsMini
            />
            <RouteWithLayout
              exact
              path='/createdCourses/:courseID/:tab'
              Component={<CreateCourses />}
              isAuth={isAuth}
              allowedRoles={[Roles.SCHOOL_OWNER, Roles.TEACHER, Roles.MANAGER, Roles.ADMIN]}
              userRole={role}
              leftSideIsMini
            />
            <RouteWithLayout
              exact
              path='/createdCourses/:courseID/:tab/:moduleID'
              Component={<CreateCourses />}
              isAuth={isAuth}
              allowedRoles={[Roles.SCHOOL_OWNER, Roles.TEACHER, Roles.MANAGER, Roles.ADMIN]}
              userRole={role}
              leftSideIsMini
            />
            <RouteWithLayout
              exact
              path='/createdCourses/:courseID/:tab/:moduleID/:lessonID'
              Component={<CreateCourses />}
              isAuth={isAuth}
              allowedRoles={[Roles.SCHOOL_OWNER, Roles.TEACHER, Roles.MANAGER, Roles.ADMIN]}
              userRole={role}
              leftSideIsMini
            />
            <RouteWithLayout
              exact
              path='/preview/:courseID'
              Component={<Preview />}
              isAuth={isAuth}
              allowedRoles={[Roles.SCHOOL_OWNER, Roles.MANAGER, Roles.ADMIN, Roles.TEACHER]}
              userRole={role}
              showLeftSide={false}
              pageClassName='preview'
              withPageContainer={false}
            />
            <RouteWithLayout
              exact
              path='/preview/:courseID/lessons'
              Component={<PreviewLessons />}
              isAuth={isAuth}
              allowedRoles={[Roles.SCHOOL_OWNER, Roles.MANAGER, Roles.ADMIN, Roles.TEACHER]}
              userRole={role}
              leftSideIsMini
            />
            <RouteWithLayout
              exact
              path='/profile'
              Component={<Profile />}
              isAuth={isAuth}
              allowedRoles={[
                Roles.SCHOOL_OWNER,
                Roles.STUDENT,
                Roles.TEACHER,
                Roles.MANAGER,
                Roles.ADMIN
              ]}
              userRole={role}
            />
            <RouteWithLayout
              exact
              path='/availableCourses'
              Component={<AvailableCourses />}
              isAuth={isAuth}
              allowedRoles={[Roles.TEACHER, Roles.SCHOOL_OWNER, Roles.MANAGER, Roles.ADMIN]}
              userRole={role}
            />
            <RouteWithLayout
              exact
              path='/check/:courseID'
              Component={<CheckTasks />}
              isAuth={isAuth}
              allowedRoles={[Roles.TEACHER, Roles.SCHOOL_OWNER, Roles.MANAGER, Roles.ADMIN]}
              userRole={role}
              leftSideIsMini
            />
            <RouteWithLayout
              exact
              path='/check/:courseID/:tab'
              Component={<CheckTasks />}
              isAuth={isAuth}
              allowedRoles={[Roles.TEACHER, Roles.SCHOOL_OWNER, Roles.MANAGER, Roles.ADMIN]}
              userRole={role}
              leftSideIsMini
            />
            <RouteWithLayout
              exact
              path='/check/:courseID/:tab/:moduleID'
              Component={<CheckTasks modulePage='lesson' />}
              isAuth={isAuth}
              allowedRoles={[Roles.TEACHER, Roles.SCHOOL_OWNER, Roles.MANAGER, Roles.ADMIN]}
              userRole={role}
              leftSideIsMini
            />
            <RouteWithLayout
              exact
              path='/check/:courseID/:tab/:moduleID/:lessonID'
              Component={<CheckTasks modulePage='task' />}
              isAuth={isAuth}
              allowedRoles={[Roles.TEACHER, Roles.SCHOOL_OWNER, Roles.MANAGER, Roles.ADMIN]}
              userRole={role}
              leftSideIsMini
            />
            <RouteWithLayout
              exact
              path='/check/:courseID/:tab/:moduleID/:lessonID/:taskID'
              Component={<CheckTasks modulePage='exercise' />}
              isAuth={isAuth}
              allowedRoles={[
                Roles.TEACHER,
                Roles.SCHOOL_OWNER,
                Roles.MANAGER,
                Roles.ADMIN,
                Roles.STUDENT
              ]}
              userRole={role}
              leftSideIsMini
            />
            <RouteWithLayout
              exact
              path='/usersStatistics'
              Component={<UsersStatistics />}
              leftSideIsMini
              isAuth={isAuth}
              allowedRoles={[
                Roles.SCHOOL_OWNER,
                Roles.TEACHER,
                Roles.MANAGER,
                Roles.ADMIN,
                Roles.STUDENT
              ]}
              userRole={role}
            />
            <RouteWithLayout
              exact
              path='/students'
              Component={<Students />}
              leftSideIsMini
              isAuth={isAuth}
              allowedRoles={[Roles.TEACHER, Roles.SCHOOL_OWNER, Roles.MANAGER, Roles.ADMIN]}
              userRole={role}
            />
            <RouteWithLayout
              exact
              path='/longread'
              Component={<PageInLongreadFormat />}
              isAuth={isAuth}
              allowedRoles={[
                Roles.SCHOOL_OWNER,
                Roles.STUDENT,
                Roles.TEACHER,
                Roles.MANAGER,
                Roles.ADMIN
              ]}
              userRole={role}
              showFooter={false}
              showLeftSide={false}
              withPageContainer={false}
            />
            <RouteWithLayout
              exact
              path='/longread/:courseID'
              Component={<PageInLongreadFormat />}
              isAuth={isAuth}
              allowedRoles={[
                Roles.SCHOOL_OWNER,
                Roles.STUDENT,
                Roles.TEACHER,
                Roles.MANAGER,
                Roles.ADMIN
              ]}
              userRole={role}
              showFooter={false}
              showLeftSide={false}
              withPageContainer={false}
            />
            <RouteWithLayout
              exact
              path='/longread/:courseID/:tab'
              Component={<PageInLongreadFormat />}
              isAuth={isAuth}
              allowedRoles={[
                Roles.SCHOOL_OWNER,
                Roles.STUDENT,
                Roles.TEACHER,
                Roles.MANAGER,
                Roles.ADMIN
              ]}
              userRole={role}
              showFooter={false}
              showLeftSide={false}
              withPageContainer={false}
            />
            <RouteWithLayout
              exact
              path='/longread/:courseID/:tab/:moduleID'
              Component={<PageInLongreadFormat />}
              isAuth={isAuth}
              allowedRoles={[
                Roles.SCHOOL_OWNER,
                Roles.STUDENT,
                Roles.TEACHER,
                Roles.MANAGER,
                Roles.ADMIN
              ]}
              userRole={role}
              showFooter={false}
              showLeftSide={false}
              withPageContainer={false}
            />
            <RouteWithLayout
              exact
              path='/longread/:courseID/:tab/:moduleID/:lessonID'
              Component={<PageInLongreadFormat />}
              isAuth={isAuth}
              allowedRoles={[
                Roles.SCHOOL_OWNER,
                Roles.STUDENT,
                Roles.TEACHER,
                Roles.MANAGER,
                Roles.ADMIN
              ]}
              userRole={role}
              showFooter={false}
              showLeftSide={false}
              withPageContainer={false}
            />
            <Route path='/404' component={() => <NotFound pageClassName='page404' />} />
            <Route component={() => <NotFound />} />
          </Switch>
        </Suspense>
      </ErrorBoundary>
    </>
  );
};

export default Routes;
