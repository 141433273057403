import { apiGetEducation } from "api/profile";
import { ProfileType } from "reducers/ProfileReducer/types";
import { getTranslation } from "src/providers/I18nProvider";
import { createNotification } from "src/providers/NotificationsProvider";
import { ActionSetProfileType } from "store/actions/profile/types";
import SET_PROFILE from "store/constants/profile";
import store, { AppThunk } from "store/index";

export const actionSetProfile = (fields: ProfileType): ActionSetProfileType => ({
  type: SET_PROFILE,
  fields
});

export const actionGetEducation = (): AppThunk => {
  return (dispatch) => {
    apiGetEducation()
      .then((e) => {
        if (e) {
          const { data } = e.data;
          dispatch(
            actionSetProfile({
              ...store.getState().profileReducer,
              educationList: data
            })
          );
        }
      })
      .catch(() => {
        createNotification("error", getTranslation(`response.422`));
      });
  };
};
