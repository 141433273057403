import { CourseGeneralState } from "reducers/CourseGeneralReducer/types";
import { CourseGeneralActionTypes } from "store/actions/courseGeneral/types";
import CourseGeneralTypes from "store/constants/courseGeneralTypes";

const initialState: CourseGeneralState = {
  categories: [],
  styles: [],
  practices: [],
  certificates: [],
  contentType: []
};

const CourseGeneralReducer = (
  state = initialState,
  action: CourseGeneralActionTypes
): CourseGeneralState => {
  switch (action.type) {
    case CourseGeneralTypes.SET_CATEGORIES:
      return {
        ...state,
        categories: action.categories
      };
    case CourseGeneralTypes.SET_STYLES:
      return {
        ...state,
        styles: action.styles
      };
    case CourseGeneralTypes.SET_CERTIFICATES:
      return {
        ...state,
        certificates: action.certificates
      };
    case CourseGeneralTypes.SET_PRACTICES:
      return {
        ...state,
        practices: action.practices
      };
    case CourseGeneralTypes.SET_CONTENT_TYPE:
      return {
        ...state,
        contentType: action.contentType
      };
    default:
      return state;
  }
};

export default CourseGeneralReducer;
