import classNames from "classnames/bind";
import { Close } from "components/Icons/Icons";
import React from "react";
import Modal from "react-modal";
import useAnimatedModal from "src/hooks/useAnimatedModal";
import { Button } from "src/shared/ui/Button/Button";
import Styles from "./ModalWrapper.module.scss";

const cnb = classNames.bind(Styles);

type Props = {
  isVisible: boolean;
  closeModal?: () => void;
  actionType?: "invite" | "delete";
  submit: (() => void) | null;
  isLoading?: boolean;
  isDisabled?: boolean;
  showHeader?: boolean;
  title?: string;
  submitButtonText?: string;
  cancelButtonText?: string;
  cancelAction?: () => void;
  customControls?: () => JSX.Element;
  disabledPadding?: boolean;
  overlayClassName?: string;
  contentClassName?: string;
  containerClassName?: string;
  className?: string;
  tabs?: JSX.Element;
} & Omit<Modal.Props, "isOpen">;

const ModalWrapper: React.FC<Props> = ({
  isVisible,
  closeModal,
  actionType = "invite",
  submit,
  isLoading,
  children,
  title,
  isDisabled,
  overlayClassName,
  contentClassName,
  containerClassName,
  className,
  showHeader = true,
  submitButtonText,
  cancelButtonText,
  cancelAction,
  customControls,
  disabledPadding,
  tabs,
  ...props
}) => {
  const { isOpen, setIsOpen } = useAnimatedModal(isVisible, closeModal);

  const cancel = () => {
    setIsOpen(false);
    if (cancelAction) cancelAction();
  };

  const controls = customControls?.() || null;

  return (
    <Modal
      isOpen={isVisible}
      contentLabel='modalActionsForStudents'
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      onRequestClose={() => (isLoading ? null : setIsOpen(false))}
      className={cnb("modalFit", { visible: isOpen, disabledPadding }, className)}
      overlayClassName={cnb("modalContainer", { visible: isOpen }, overlayClassName)}
      ariaHideApp={false}
      {...props}
    >
      <div className={cnb("container", { disabledPadding }, containerClassName)}>
        {showHeader && (
          <div className={cnb("header")}>
            <div />
            <div className={cnb("title")}>{title || ""}</div>
            <div className={cnb("closeIcon")} onClick={() => (isLoading ? null : setIsOpen(false))}>
              <Close />
            </div>
          </div>
        )}

        {tabs}

        <div className={cnb(contentClassName, cnb("main", { withTabs: tabs }))}>{children}</div>

        {!controls && submit && (
          <div className={cnb("controls", { center: !showHeader })}>
            <Button
              disabled={isLoading}
              onClick={cancel}
              variant={actionType === "delete" ? "default" : "outlined"}
              className={cnb("button", "cancleButton")}
            >
              {cancelButtonText || "Отменить"}
            </Button>
            <Button
              disabled={isDisabled}
              loading={isLoading}
              onClick={submit}
              className={cnb("button", actionType === "delete" && "deleteButton")}
              variant={actionType === "delete" ? "error" : "default"}
            >
              {submitButtonText || (actionType === "delete" ? "Удалить" : "Отправить")}
            </Button>
          </div>
        )}

        {controls}
      </div>
    </Modal>
  );
};

export default ModalWrapper;
