import classNames from "classnames/bind";
import Avatar from "components/Avatar/Avatar";
import { LoginButtonIcon, LogoTalantaNew } from "components/Icons/Icons";
import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import useWindowDimensions from "src/hooks/useWindowDimensions";
import { Button } from "src/shared/ui/Button/Button";
import { isShowLogo } from "src/utils/adaptiveUtils/isShowLogo";
import { getDefaultPath } from "src/utils/roles";
import { RootState } from "store/index";
import Styles from "../Burger.module.scss";

const cnb = classNames.bind(Styles);

interface Props {
  theme: ThemeType;
  page?: "main" | "profile";
  onlyIcons?: boolean;
  className?: string;
  isActiveBurger: boolean;
  toggleMenuBurger: (event: React.MouseEvent) => void;
  isNav?: boolean;
}

export type ThemeType = "light" | "dark";

const BurgerHeader: React.FC<Props> = ({
  isActiveBurger,
  toggleMenuBurger,
  theme,
  page,
  isNav
}) => {
  const { width } = useWindowDimensions();
  const { role, isAuth } = useSelector((state: RootState) => state.login);
  const showLogo: boolean = isShowLogo(width);

  const logo = (
    <NavLink to='/'>
      <LogoTalantaNew fill={isActiveBurger && theme !== "light" ? "white" : "undefined"} />
    </NavLink>
  );

  return (
    <div
      className={cnb("headerWrapper", {
        bgDark: theme === "dark",
        bgLight: theme === "light",
        noBorder: isActiveBurger || isNav
      })}
    >
      <div className={cnb("headerBlock")}>
        <div className={cnb("logoContainer")}>
          {page === "profile" ? (
            <NavLink to={getDefaultPath(role)}>
              <LogoTalantaNew fill='#fff' />
            </NavLink>
          ) : null}
          {page === "main" && showLogo ? logo : null}
        </div>
      </div>
      <div className={cnb("headerBlock")}>
        {page === "profile" && <Avatar onlyImage isSmall />}
        {page === "main" && (
          <>
            <Button
              as={NavLink}
              to='/login'
              activeClassName='active'
              className={cnb("loginButton")}
            >
              {isAuth ? "В личный кабинет" : "Войти"}
            </Button>

            <Button
              as={NavLink}
              to='/login'
              activeClassName='active'
              className={cnb("loginButton", "loginButtonWithIcon")}
            >
              <LoginButtonIcon />
            </Button>
          </>
        )}
        <button className={cnb("hamburger")} type='button' onClick={toggleMenuBurger}>
          <span
            className={cnb(
              { hamburgerMainRowActive: isActiveBurger },
              {
                hamburgerMainRow: !isActiveBurger
              },
              theme !== "dark" && "dark"
            )}
          />
        </button>
      </div>
      {page !== "main" && (
        <div className={cnb("gradient")}>
          <svg
            width='288'
            height='317'
            viewBox='0 0 288 317'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <g opacity='0.3'>
              <g filter='url(#filter0_f_7712_4622)'>
                <ellipse
                  cx='119.884'
                  cy='102.243'
                  rx='119.884'
                  ry='102.243'
                  transform='matrix(-0.857563 0.51438 0.51438 0.857563 361.602 -135)'
                  fill='url(#paint0_linear_7712_4622)'
                />
              </g>
              <g filter='url(#filter1_f_7712_4622)'>
                <ellipse
                  cx='101.717'
                  cy='74.8119'
                  rx='101.717'
                  ry='74.8119'
                  transform='matrix(-0.857563 0.51438 0.51438 0.857563 326.133 -75.9258)'
                  fill='url(#paint1_linear_7712_4622)'
                />
              </g>
            </g>
            <defs>
              <filter
                id='filter0_f_7712_4622'
                x='0.717712'
                y='-288.023'
                width='621.338'
                height='604.734'
                filterUnits='userSpaceOnUse'
                colorInterpolationFilters='sRGB'
              >
                <feFlood floodOpacity='0' result='BackgroundImageFix' />
                <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
                <feGaussianBlur stdDeviation='97.5826' result='effect1_foregroundBlur_7712_4622' />
              </filter>
              <filter
                id='filter1_f_7712_4622'
                x='84.4409'
                y='-139.819'
                width='385.892'
                height='360.741'
                filterUnits='userSpaceOnUse'
                colorInterpolationFilters='sRGB'
              >
                <feFlood floodOpacity='0' result='BackgroundImageFix' />
                <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
                <feGaussianBlur stdDeviation='48.7913' result='effect1_foregroundBlur_7712_4622' />
              </filter>
              <linearGradient
                id='paint0_linear_7712_4622'
                x1='119.884'
                y1='0'
                x2='119.884'
                y2='204.486'
                gradientUnits='userSpaceOnUse'
              >
                <stop stopColor='#FF7BCA' />
                <stop offset='1' stopColor='#FFC56F' stopOpacity='0.46' />
              </linearGradient>
              <linearGradient
                id='paint1_linear_7712_4622'
                x1='101.717'
                y1='0'
                x2='101.717'
                y2='149.624'
                gradientUnits='userSpaceOnUse'
              >
                <stop stopColor='#F566C5' />
                <stop offset='1' stopColor='#F58A25' stopOpacity='0' />
              </linearGradient>
            </defs>
          </svg>
        </div>
      )}
    </div>
  );
};

export default BurgerHeader;
