import classNames from "classnames/bind";
import React from "react";
import Styles from "./Loader.module.scss";

const cnb = classNames.bind(Styles);

interface Props {
  className?: string;
  small?: boolean;
}

const Loader: React.FC<Props> = ({ className, small }) => {
  return <div className={cnb("lds-dual-ring", className, { small })} />;
};

export default Loader;
