import { apiGetProfile, ApiProfileResponse } from "api/profile";
import { useQuery } from "react-query";
import { actionSetProfile } from "store/actions/profile";
import store from "../../store";
import Queries from "./queriesList";

export const placeholderData: ApiProfileResponse = {
  data: {
    data: {
      email: "",
      school_id: [],
      profile: {
        id: 0,
        name: "",
        surname: "",
        profile_picture: "",
        city: "",
        phone: "",
        work: "",
        date_of_birth: "",
        gender: 0,
        education_id: 0,
        timezone_id: 0,
        language_id: 0
      },
      permissions: [],
      compilation: {
        id: "",
        name: "",
        place: "main",
        position: 0,
        courses: []
      },
      user_id: 0,
      roles: [],
      name: "",
      surname: "",
      phone: "",
      offers: []
    }
  }
};

export const useProfileData = () => {
  const { data, isLoading } = useQuery(Queries.PROFILE_DATA, apiGetProfile, {
    placeholderData,
    cacheTime: 1000 * 60 * 10,
    staleTime: 1000 * 60
  });

  const userID = store.getState().profileReducer.user_id;

  if (userID === 0 && data?.data.data.user_id) {
    store.dispatch(
      actionSetProfile({
        ...store.getState().profileReducer,
        user_id: data?.data.data.user_id
      })
    );
  }

  return { data: data?.data?.data || placeholderData.data.data, isLoading };
};
