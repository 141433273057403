export type NavItem = {
  title: string;
  link: string;
  button?: boolean;
  isExternalLink?: boolean;
};

export const navItems: NavItem[] = [
  {
    title: "Стать автором",
    link: "#author"
  },
  {
    title: "Каталог курсов",
    link: "/course"
  },
  // {
  //   title: "О Talanta",
  //   link: "#"
  // },
  {
    title: "Школы и авторы",
    link: "/schools"
  },
  {
    title: "Блог",
    link: "/landing/articles",
    isExternalLink: true
  },
  {
    title: "Войти",
    link: "/login",
    button: true
  }
];

export const navItemsInner: NavItem[] = [
  {
    title: "Стать автором",
    link: "#author"
  },
  {
    title: "Каталог курсов",
    link: "/course"
  },
  // {
  //   title: "О Talanta",
  //   link: "#"
  // },
  {
    title: "Школы и авторы",
    link: "/schools"
  },
  {
    title: "Блог",
    link: "/landing/articles",
    isExternalLink: true
  },
  {
    title: "На главную",
    link: "/"
  }
];
