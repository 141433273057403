import classNames from "classnames";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { actionGetAllRoles } from "store/actions/login";

interface Props {
  children: React.ReactNode;
  leftSideIsMini?: boolean;
  className?: string;
}

const PageContainer: React.FC<Props> = ({ children, leftSideIsMini, className }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actionGetAllRoles());
  }, [dispatch]);

  return (
    <div className={classNames("pageContainer", { navMinimized: leftSideIsMini }, className)}>
      {children}
    </div>
  );
};

export default PageContainer;
