import classNames from "classnames/bind";
import { Check, Close, Eye, EyeClosed } from "components/Icons/Icons";
import React, { ChangeEventHandler, FocusEvent, FocusEventHandler, useEffect, useRef } from "react";
import { IMaskInput } from "react-imask";
import { blacklist } from "src/shared/utils/validation/blacklist";
import { ErrorType, RequiredFieldType } from "store/actions/course/types";
import Styles from "./Input.module.scss";

const cnb = classNames.bind(Styles);

export type InputProps = {
  value: string | undefined | number;
  setValue: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  title?: string;
  id?: string;
  name?: string;
  type?: "text" | "number" | "email" | "tel";
  touched?: boolean;
  classNameForWrapper?: string;
  leftIcon?: JSX.Element;
  rightIcon?: JSX.Element;
  isTextArea?: boolean;
  password?: boolean;
  disabled?: boolean;
  onIconClick?: () => void;
  doubleIcons?: boolean;
  correctAnswer?: boolean;
  onFirstRightIconClick?: () => void;
  onSecondRightIconClick?: () => void;
  error?: string;
  errors?: ErrorType;
  handleBlur?: FocusEventHandler;
  handleFocus?: FocusEventHandler;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  phone?: boolean;
  placeholder?: string;
  required?: boolean;
  inputRef?: React.LegacyRef<HTMLInputElement>;
};

const Input = ({
  value,
  setValue,
  title,
  id = "input",
  type = "text",
  name = "name",
  classNameForWrapper,
  leftIcon,
  rightIcon,
  isTextArea = false,
  disabled = false,
  password,
  onIconClick,
  doubleIcons,
  correctAnswer,
  onFirstRightIconClick,
  onSecondRightIconClick,
  error,
  errors,
  touched,
  placeholder,
  handleBlur,
  handleFocus,
  onKeyDown,
  phone,
  required,
  inputRef
}: InputProps): JSX.Element => {
  const [focused, setFocused] = React.useState(false);
  const ref: any = useRef();

  const onFocus = (e: FocusEvent) => {
    setFocused(true);
    if (handleFocus) {
      handleFocus(e);
    }
  };
  const onBlur = (e: FocusEvent) => {
    setFocused(false);
    if (handleBlur) {
      handleBlur(e);
    }
  };
  const [hideText, setHideText] = React.useState(password);
  const requiredField = (!!name && Object.values(RequiredFieldType).includes(name)) || required;
  const haveError =
    !!name && typeof value === "string" && !value?.length && !!errors?.errors.includes(name);
  const phoneMask = "0-000-000-0000";

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getInputStyles = () => {
    return cnb(
      "input",
      { focused: focused || value || value === 0 },
      { error: (error && touched) || haveError },
      { withLeftIcon: leftIcon },
      { withoutTitle: !title?.length },
      { withRightIcon: rightIcon || password },
      { withDoubleIcon: doubleIcons },
      { correctAnswer },
      { disabled }
    );
  };

  useEffect(() => {
    if (ref.current) {
      ref.current.element.className = getInputStyles();
      ref.current.element.onblur = onBlur;
      ref.current.element.onfocus = onFocus;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getInputStyles]);

  const checkMaskedInput = () => {
    if (phone) {
      return (
        <IMaskInput
          mask={phoneMask}
          value={value}
          onAccept={(maskedValue) => {
            setValue(blacklist(maskedValue as string, "-()+") as any);
          }}
          ref={ref}
        />
      );
    }
    return (
      <input
        ref={inputRef}
        id={id}
        name={name}
        value={value}
        onChange={setValue}
        disabled={disabled}
        type={password && hideText ? "password" : type}
        required={requiredField}
        className={getInputStyles()}
        onBlur={onBlur}
        onFocus={onFocus}
        placeholder={placeholder}
        onKeyDown={onKeyDown}
      />
    );
  };

  return (
    <div
      className={cnb(
        "wrapper",
        classNameForWrapper,
        { error: (error && touched) || haveError },
        disabled
      )}
    >
      <div
        className={cnb(
          "title",
          { focused: focused || value || value === 0 },
          { withLeftIcon: leftIcon },
          { withRightIcon: rightIcon }
        )}
      >
        <label htmlFor={id}>{title}</label>
      </div>
      <div className={cnb("inner")}>
        <div className={cnb("icon", "left", "small")} onClick={onIconClick}>
          {leftIcon}
        </div>
        {isTextArea ? (
          <div className={cnb("textAreaWrapper", { error: (error && touched) || haveError })}>
            <textarea
              id={id}
              name={name}
              value={value}
              onChange={setValue}
              disabled={disabled}
              required={requiredField}
              className={cnb(
                "input",
                "textarea",
                { focused: focused || value },

                { withLeftIcon: leftIcon },
                { withRightIcon: rightIcon || password },
                { withoutTitle: !title?.length }
              )}
              onBlur={onBlur}
              onFocus={onFocus}
            />
          </div>
        ) : (
          checkMaskedInput()
        )}
        {password ? (
          <div
            className={cnb("icon", "right", "passwordIcon")}
            onClick={() => setHideText((prev) => !prev)}
          >
            {hideText ? <Eye width={32} /> : <EyeClosed width={32} />}
          </div>
        ) : (
          <div
            className={cnb("icon", "right", { disabled })}
            onClick={() => (!disabled ? onIconClick?.() : null)}
          >
            {rightIcon}
          </div>
        )}

        {error && touched && <span className={cnb("errorField")}>!</span>}
        {error && touched && !isTextArea && <div className={cnb("errorText")}>{error}</div>}
        {doubleIcons && (
          <>
            <div
              className={cnb("icon", "right", "second", { correct: correctAnswer }, { disabled })}
              onClick={() => (!disabled ? onFirstRightIconClick?.() : null)}
            >
              <Check />
            </div>
            <div
              className={cnb("icon", "right", { disabled })}
              onClick={() => (!disabled ? onSecondRightIconClick?.() : null)}
            >
              <Close />
            </div>
          </>
        )}
      </div>
      {error && touched && isTextArea && (
        <div className={cnb("errorText", "bottomError")}>{error}</div>
      )}
    </div>
  );
};

export default Input;
