import classNames from "classnames/bind";
import { useBecomeModalAuthor } from "components/ModalBecomeAuthor/lib";
import React, { useEffect, useState } from "react";

import Styles from "./Burger.module.scss";
import { navItems, navItemsInner } from "./config";
import BurgerHeader, { ThemeType } from "./сomponents/BurgerHeader";
import BurgerMainContent from "./сomponents/BurgerMainContent";
import BurgerProfileContent from "./сomponents/BurgerProfileContent";

const cnb = classNames.bind(Styles);

interface Props {
  theme: ThemeType;
  page: "main" | "profile";
  list?: Link[];
  currentPath: string;
  onlyIcons?: boolean;
  className?: string;
  isNav?: boolean;
}

interface Link {
  icon: JSX.Element;
  path: string;
  name: string;
  onClick: () => void;
  enabled: boolean;
}

const Burger: React.FC<Props> = ({ list = [], currentPath, onlyIcons, theme, page, isNav }) => {
  const [isActiveBurger, setIsActiveBurger] = useState(false);

  const { open } = useBecomeModalAuthor();

  const toggleMenuBurger = (event: React.MouseEvent, link?: string) => {
    if (link === "#author") {
      event.preventDefault();
      open();
    }

    setIsActiveBurger(!isActiveBurger);
  };

  const formattedList = list.map((item) => {
    return {
      ...item,
      onClick: () => {
        item.onClick();
        setIsActiveBurger(false);
      }
    };
  });

  useEffect(() => {
    const html = document.querySelector("html")!;
    html.style.overflow = isActiveBurger ? "hidden" : "auto";
  }, [isActiveBurger]);

  return (
    <div style={isNav ? { height: 70 } : {}}>
      <div className={cnb({ menuContainer: isActiveBurger })}>
        <BurgerHeader
          isActiveBurger={isActiveBurger}
          toggleMenuBurger={toggleMenuBurger}
          theme={theme}
          page={page}
          isNav={isNav}
        />

        {isActiveBurger && page === "profile" && (
          <BurgerProfileContent
            toggleMenuBurger={toggleMenuBurger}
            theme={theme}
            list={formattedList}
            currentPath={currentPath}
            onlyIcons={onlyIcons}
            items={navItemsInner}
            isActiveBurger
          />
        )}
        {isActiveBurger && page === "main" && (
          <BurgerMainContent
            theme={theme}
            items={navItems}
            onClickItem={() => setIsActiveBurger(false)}
          />
        )}
      </div>
    </div>
  );
};

export default Burger;
