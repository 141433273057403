import classNames from "classnames/bind";
import React from "react";
import Styles from "./NavMenu.module.scss";

const cnb = classNames.bind(Styles);

interface Props {
  list: Link[];
  currentPath: string;
  onlyIcons?: boolean;
  toggleMenuBurger?: (event: React.MouseEvent, link?: string) => void;
}

interface Link {
  icon: JSX.Element;
  path: string;
  name: string;
  onClick: () => void;
  enabled: boolean;
}

const NavMenu: React.FC<Props> = ({ list, currentPath, onlyIcons, toggleMenuBurger }) => {
  return (
    <div className={cnb("container")}>
      {list
        .filter((item) => item.enabled === true)
        .map((link) => {
          return (
            <div
              className={cnb("item", { active: currentPath === link.path })}
              onClick={link.onClick}
              key={link.path}
            >
              <div className={cnb("iconContainer")}>{link.icon}</div>

              <span onClick={toggleMenuBurger} className={cnb("name", onlyIcons && "mobileHidden")}>
                {link.name}
              </span>
            </div>
          );
        })}
    </div>
  );
};

export default NavMenu;
