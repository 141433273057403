import { CreatedCourseListInitialState } from "reducers/CreatedCourseReducer/types";
import { CreatedCourseTypes } from "store/actions/createdCourse/types";
import CreatedCourseConstant from "store/constants/createdCourse";

const initialState: CreatedCourseListInitialState = {
  courseList: []
};

const CreatedCourseReducer = (
  state = initialState,
  action: CreatedCourseTypes
): CreatedCourseListInitialState => {
  switch (action.type) {
    case CreatedCourseConstant.SET_CREATED_COURSE: {
      return {
        courseList: action.append ? state.courseList.concat(action.courseList) : action.courseList
      };
    }
    case CreatedCourseConstant.CLEAR_CREATED_LIST: {
      return {
        courseList: []
      };
    }
    case CreatedCourseConstant.REMOVE_COURSE: {
      return {
        ...state,
        courseList: state.courseList.filter((item) => item.id !== action.courseID)
      };
    }
    default:
      return state;
  }
};

export default CreatedCourseReducer;
