/* eslint-disable @typescript-eslint/no-explicit-any */
import classNames from "classnames/bind";
import React, {
  DetailedHTMLProps,
  InputHTMLAttributes,
  forwardRef,
  useRef,
  useState,
  useImperativeHandle,
  useEffect
} from "react";

import intlTelInput from "intl-tel-input";

import Styles from "./PhoneInput.module.scss";
import { Input } from "../Input/Input";

const cnb = classNames.bind(Styles);

type Props = {
  error?: string;
  label?: string;
  mask?: string;
  onAccept?: (value: string) => void;
} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export const PhoneInput = forwardRef<{ iti: any }, Props>(
  ({ className, error, label, ...props }, ref) => {
    const [iti, setIti] = useState<any>();

    const input = useRef<HTMLDivElement | null>(null);
    const container = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
      const telInput = input.current;
      let lengthMaskTelInput = 11;
      const validateLength = (ev) => {
        if (ev.target.value.length > lengthMaskTelInput) {
          ev.target.value = ev.target.value.slice(0, ev.target.value.length - 1);
        }
      };

      if (telInput && container.current) {
        const el = intlTelInput(telInput, {
          initialCountry: "ru",
          preferredCountries: ["ru", "kz", "by", "ge", "us"],
          autoPlaceholder: "aggressive",
          utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@18.2.1/build/js/utils.js",
          dropdownContainer: container.current
        });
        setIti(el);
        document.body.classList.remove("iti-mobile");

        telInput.addEventListener("input", validateLength);
        telInput.addEventListener("countrychange", () => {
          const countryCode = el.getSelectedCountryData().dialCode;

          if (countryCode === "7") lengthMaskTelInput = 11;
          else lengthMaskTelInput = 13;
        });
      }
      return () => {
        if (telInput) telInput.removeEventListener("input", validateLength);
      };
    }, [input, container]);

    useImperativeHandle(ref, () => ({
      iti
    }));

    return (
      <div className={cnb("dropdownContainer")} ref={container}>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <Input placeholder=' ' type='tel' ref={input} {...props} />
        {error && <div className={cnb("errorInfo")}>{error}</div>}
      </div>
    );
  }
);

PhoneInput.displayName = "PhoneInput";
