/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/no-shadow */
import classNames from "classnames/bind";
import Avatar from "components/Avatar/Avatar";
import Burger from "components/Burger/Burger";
import {
  Arrow,
  CheckCourseIcon,
  CourseList,
  CreatedCourseIcon,
  Exit,
  LogoTalantaNew,
  SectionStatisticsIcon,
  SmallLogoTalanta,
  StudentsPageIcon,
  User
} from "components/Icons/Icons";
import NavMenu from "components/NavMenu/NavMenu";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import { Roles } from "reducers/LoginReducer/types";
import { useProfileData } from "src/hooks/queries/useProfileData";
import usePathname from "src/hooks/usePathname";
import useWindowDimensions from "src/hooks/useWindowDimensions";
import { isShowBurger } from "src/utils/adaptiveUtils/isShowBurger";
import { getDefaultPath } from "src/utils/roles";
import { actionLogout } from "store/actions/login";
import { RootState } from "store/index";
import Styles from "./Nav.module.scss";

const cnb = classNames.bind(Styles);

interface Props {
  isHiding?: boolean;
}
const Nav: React.FC<Props> = ({ isHiding }) => {
  const [isMiniMenu, setIsMiniMenu] = useState(isHiding ?? false);

  const path = usePathname();
  const history = useHistory();
  const dispatch = useDispatch();
  const fill = "white";
  const { role } = useSelector((state: RootState) => state.login);

  const {
    data: { profile }
  } = useProfileData();

  const logout = () => {
    history.push("/myCourses");
  };

  const list = [
    {
      icon: <CourseList fill={fill} />,
      path: "/myCourses",
      name: "Учебные материалы",
      onClick: () => history.push("/myCourses"),
      enabled: true
    },
    {
      icon: <CreatedCourseIcon />,
      path: "/createdCourses",
      name: "Мои курсы",
      onClick: () => history.push("/createdCourses"),
      enabled:
        role === Roles.TEACHER ||
        role === Roles.ADMIN ||
        role === Roles.SCHOOL_OWNER ||
        role === Roles.MANAGER
    },
    {
      icon: <CheckCourseIcon fill={fill} />,
      path: "/availableCourses",
      name: "Проверка заданий",
      onClick: () => history.push("/availableCourses"),
      enabled:
        role === Roles.TEACHER ||
        role === Roles.ADMIN ||
        role === Roles.SCHOOL_OWNER ||
        role === Roles.MANAGER
    },
    {
      icon: <SectionStatisticsIcon />,
      path: "/usersStatistics",
      name: "Статистика",
      onClick: () => history.push("/usersStatistics"),
      enabled:
        role === Roles.ADMIN ||
        role === Roles.STUDENT ||
        role === Roles.SCHOOL_OWNER ||
        role === Roles.MANAGER ||
        role === Roles.TEACHER
    },
    {
      icon: <StudentsPageIcon />,
      path: "/students",
      name: "Ученики",
      onClick: () => history.push("/students"),
      enabled:
        role === Roles.TEACHER ||
        role === Roles.ADMIN ||
        role === Roles.SCHOOL_OWNER ||
        role === Roles.MANAGER
    },
    {
      icon: <User fill={fill} />,
      path: "/profile",
      name: "Профиль",
      onClick: () => history.push("/profile"),
      enabled: true
    },
    {
      icon: <Exit fill={fill} />,
      path: "/exit",
      name: "Выход",
      onClick: () => dispatch(actionLogout(logout)),
      enabled: true
    }
  ];

  const { width } = useWindowDimensions();

  const showBurger = isShowBurger(width);
  const smallMenu = isHiding || isMiniMenu;

  return (
    <>
      {showBurger ? (
        <Burger
          isNav
          list={list}
          currentPath={path}
          onlyIcons={isHiding}
          theme='dark'
          page='profile'
        />
      ) : (
        <div className={cnb("container", { isHiding: smallMenu })}>
          <div className={cnb("logoContainer")}>
            <NavLink to={getDefaultPath(role)}>
              {smallMenu ? <SmallLogoTalanta /> : <LogoTalantaNew fill='currentColor' />}
            </NavLink>
          </div>

          {profile && (
            <Avatar name={`${profile.name ?? ""} ${profile.surname ?? ""}`} onlyImage={smallMenu} />
          )}

          {!isHiding && (
            <div
              onClick={() => setIsMiniMenu(!isMiniMenu)}
              className={cnb("toggleArrow", { right: isMiniMenu })}
            >
              <Arrow fill='#fff' />
            </div>
          )}
          <NavMenu list={list} currentPath={path} onlyIcons={smallMenu} />
        </div>
      )}
    </>
  );
};

export default Nav;
