import {
  AccessType,
  ActionType,
  AdditionalType,
  ConsumersType,
  CourseType,
  GeneralType,
  LearnsType,
  PaymentType,
  ProgramType,
  ResultsType,
  StudentsStatistics,
  StudentsType,
  StyleType,
  TableListType,
  TeacherType
} from "reducers/CourseReducer/types";
import CourseConstants from "store/constants/course";

export type ActionSetCourseType = {
  type: CourseConstants.SET_COURSE;
  courseID: CourseType["courseID"];
  action?: "create" | "remove";
};

export type ActionSetAboutGeneral = {
  type: CourseConstants.SET_ABOUT_GENERAL;
  courseID: CourseType["courseID"];
  school?: CourseType["school"];
  fields: GeneralType;
  hash?: string;
  need_to_be_paid?: boolean;
};

export type ActionSetAboutAdditional = {
  type: CourseConstants.SET_ABOUT_ADDITIONAL;
  courseID: CourseType["courseID"];
  fields: AdditionalType;
  fieldType?: "price" | "completionDoc" | "practiceType" | "description" | "old_price";
};

export type ActionSetAboutPrograms = {
  type: CourseConstants.SET_ABOUT_PROGRAMS;
  courseID: CourseType["courseID"];
  fields: ProgramType;
  actionType: ActionWithCourseType;
  confirmation?: boolean;
};

export type ActionSetAboutResults = {
  type: CourseConstants.SET_ABOUT_RESULTS;
  courseID: CourseType["courseID"];
  fields: ResultsType;
  actionType: ActionWithCourseType;
};

export type ActionSetAboutConsumers = {
  type: CourseConstants.SET_ABOUT_CONSUMERS;
  courseID: CourseType["courseID"];
  fields: ConsumersType;
  actionType: ActionWithCourseType;
};

export type ActionSetAboutLearns = {
  type: CourseConstants.SET_ABOUT_LEARNS;
  courseID: CourseType["courseID"];
  fields: LearnsType;
  actionType: ActionWithCourseType;
};

export type ActionSetAboutStyle = {
  type: CourseConstants.SET_ABOUT_STYLE;
  courseID: CourseType["courseID"];
  fields: StyleType;
};

export type ActionSetAboutTeachers = {
  type: CourseConstants.SET_ABOUT_TEACHERS;
  courseID: CourseType["courseID"];
  fields: Partial<TeacherType>;
  actionType: ActionWithCourseType;
  confirmation?: boolean;
};

export type ActionSetAboutAuthor = {
  type: CourseConstants.SET_ABOUT_AUTHOR;
  courseID: CourseType["courseID"];
  fields: { authorName: string };
};

export type ActionSetAboutCourseProgress = {
  type: CourseConstants.SET_ABOUT_COURSE_PROGRESS;
  courseID: CourseType["courseID"];
  fields: { completedLessonsAmount: number; lessonsAmount: number; progress: number };
};

export type ActionSetStudents = {
  type: CourseConstants.SET_STUDENTS;
  courseID: CourseType["courseID"];
  fields: TableListType | StudentsType[];
};

export type ActionSetSettingsAccess = {
  type: CourseConstants.SET_SETTINGS_ACCESS;
  courseID: CourseType["courseID"];
  fields: AccessType;
};

export type ActionSetSettingsPayments = {
  type: CourseConstants.SET_SETTINGS_PAYMENT;
  courseID: CourseType["courseID"];
  fields: PaymentType;
};

export type ActionSetSettingsActions = {
  type: CourseConstants.SET_SETTINGS_ACTION;
  courseID: CourseType["courseID"];
  fields: ActionType;
};

export type ActionSetStatsCourse = {
  type: CourseConstants.SET_STATS_TABLE_COURSE;
  courseID: CourseType["courseID"];
  fields: [];
};

export type ActionSetStatsStudents = {
  type: CourseConstants.SET_STATS_TABLE_STUDENTS;
  courseID: CourseType["courseID"];
  fields: StudentsStatistics[];
};

export type ActionSetStatsLessons = {
  type: CourseConstants.SET_STATS_TABLE_LESSONS;
  courseID: CourseType["courseID"];
  fields: [];
};

export type ActionSetSelectedSchoolID = {
  type: CourseConstants.SET_SELECTED_SCHOOL_ID;
  courseID: CourseType["courseID"];
  fields: { schoolID: number };
};

export type ActionWithCourseType =
  | "create"
  | "read"
  | "update"
  | "markForDelete"
  | "delete"
  | "restore"
  | "clear";

export type ErrorType = {
  errors: string[];
};

export const RequiredFieldType = {
  courseGeneralName: "general.name",
  courseGeneralCategory: "general.category",
  courseAdditionalCoast: "additional.price",
  courseAdditionalPracticeType: "additional.practiceType",
  courseAdditionalCompletionDoc: "additional.completionDoc",
  courseTeacherName: "teachers.i.name",
  courseTeacherDescription: "teachers.i.description",
  courseTeacherImage: "teachers.i.image",
  schoolID: "selectedSchoolID",
  consumers: "consumers.i.description",
  learns: "learns.i.description",
  results: "results.i.name"
};

export const ValidateMaxLength = {
  "results.i.name": 45,
  "results.i.description": 200,
  "learns.i.description": 45,
  "consumers.i.description": 45,
  "programs.i.name": 256
};

export const ValidateMaxLengthMessage = {
  "results.i.name": "Название результатов",
  "results.i.description": "Описание результатов",
  "learns.i.description": "Описание блока чему научитесь",
  "consumers.i.description": "Описание блока кому подойдет курс",
  "programs.i.name": "Название программы"
};

export const RequiredFieldTypeMessage = {
  courseGeneralName: "Не заполнено название курса",
  courseGeneralCategory: "Не выбрана категория",
  courseAdditionalCoast: "Не заполнена стоимость",
  courseAdditionalPracticeType: "Не выбран тип практики",
  courseAdditionalCompletionDoc: "Не выбран документ о завершении практики",
  courseTeacherName: "Не заполнено имя преподавателя",
  courseTeacherDescription: "Не заполнено описание преподавателя",
  courseTeacherImage: "Не загружена фотография преподавателя",
  schoolID: "Не выбрана школа",
  consumers: "В блоке Кому подойдет курс не заполнено одно из описаний",
  learns: "В блоке Чему научитесь не заполнено одно из описаний",
  results: "В блоке Результаты не заполнен один из заголовков"
};

export type CourseActionTypes =
  | ActionSetCourseType
  | ActionSetAboutGeneral
  | ActionSetAboutAdditional
  | ActionSetAboutPrograms
  | ActionSetAboutTeachers
  | ActionSetStudents
  | ActionSetSettingsAccess
  | ActionSetSettingsPayments
  | ActionSetSettingsActions
  | ActionSetStatsCourse
  | ActionSetStatsStudents
  | ActionSetAboutAuthor
  | ActionSetStatsLessons
  | ActionSetSelectedSchoolID
  | ActionSetAboutStyle
  | ActionSetAboutCourseProgress
  | ActionSetAboutResults
  | ActionSetAboutConsumers
  | ActionSetAboutLearns;
