import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translation_ru from "./ru/translation.json";

const resources = {
  ru: {
    translation_ru
  }
} as const;

i18n.use(initReactI18next).init({
  lng: "ru",
  ns: ["translation_ru"],
  interpolation: {
    escapeValue: false // not needed for react as it escapes by default
  },
  resources
});

export default i18n;
