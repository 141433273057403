import classNames from "classnames/bind";
import { navItems, navItemsInner } from "components/Burger/config";
import { useBecomeModalAuthor } from "components/ModalBecomeAuthor/lib";
import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { Button } from "src/shared/ui/Button/Button";
import { RootState } from "store/index";
import Styles from "./TopMenu.module.scss";

const cnb = classNames.bind(Styles);

export type TopMenuProps = {
  className?: string;
  isNavMain?: boolean;
};

const TopMenu: React.FC<TopMenuProps> = ({ className, isNavMain }) => {
  const list = isNavMain ? navItems : navItemsInner;

  const { open } = useBecomeModalAuthor();

  const { login } = useSelector((state: RootState) => state);
  const { isAuth } = login;

  const onClick = (event: React.MouseEvent, link: string) => {
    if (link === "#author") {
      event.preventDefault();

      open();
    }
  };

  return (
    <div className={cnb("topMenu", className)}>
      {list.map(({ title, link, button, isExternalLink }) => {
        if (button) {
          return (
            <Button as={NavLink} activeClassName='active' to={link} key={`${title}-${link}`}>
              {isAuth && button && isNavMain ? "В личный кабинет" : title}
            </Button>
          );
        }
        return (
          <>
            {isExternalLink ? (
              <a
                href={link}
                style={{ fontWeight: "semibold" }}
                key={`${title}-${link}`}
                className={cnb("link")}
              >
                {title}
              </a>
            ) : (
              <NavLink
                activeClassName='active'
                to={link}
                className={cnb("link", { button })}
                key={`${title}-${link}`}
                style={{
                  fontWeight: link === "#author" ? "500" : "semibold"
                }}
                onClick={(event) => onClick(event, link)}
              >
                {isAuth && button && isNavMain ? "В личный кабинет" : title}
              </NavLink>
            )}
          </>
        );
      })}
    </div>
  );
};

export default TopMenu;
