import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import useSetDocumentTitle from "src/hooks/useSetDocumentTitle";
import I18nProvider from "src/providers/I18nProvider";
import AuthProvider from "./providers/AuthProvider";
import InterceptorsProvider from "./providers/InterceptorsProvider";
import NotificationsProvider from "./providers/NotificationsProvider";
import ReactQueryProvider from "./providers/ReactQueryProvider";
import store from "./store";

const App = (): React.ReactElement => {
  useSetDocumentTitle("TALANTA - Образовательная платформа с онлайн-курсами");

  return (
    <Provider store={store}>
      <ReactQueryProvider>
        <BrowserRouter>
          <I18nProvider>
            <NotificationsProvider>
              <InterceptorsProvider>
                <AuthProvider />
              </InterceptorsProvider>
            </NotificationsProvider>
          </I18nProvider>
        </BrowserRouter>
      </ReactQueryProvider>
    </Provider>
  );
};

export default App;
