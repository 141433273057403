import React from "react";
import { Redirect, Route } from "react-router-dom";
import { Roles } from "reducers/LoginReducer/types";
import Layouts, { PagePropsDefault } from "scene/layouts";

interface Props extends PagePropsDefault {
  Component: JSX.Element;
  isAuth?: boolean;
  allowedRoles?: Roles[];
  userRole?: Roles;
  isPrivate?: boolean;

  [x: string]: unknown;
}

const RouteWithLayout: React.FC<Props> = ({
  Component,
  isAuth = false,
  allowedRoles,
  userRole,
  pageClassName,
  leftSideIsMini,
  showLeftSide,
  showFooter,
  withPageContainer,
  isPrivate = true,
  ...rest
}): React.ReactElement => {
  const checkIsRouteAllowed = (): boolean => {
    if (!userRole) {
      return true;
    }
    return allowedRoles ? allowedRoles.includes(userRole) : false;
  };

  const content = (
    <Layouts
      pageClassName={pageClassName}
      leftSideIsMini={leftSideIsMini}
      showLeftSide={showLeftSide}
      showFooter={showFooter}
      withPageContainer={withPageContainer}
      isPrivate={isPrivate}
    >
      {Component}
    </Layouts>
  );

  const redirectUrl = "/login";

  if (rest && rest.computedMatch) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (rest.computedMatch.path && rest.computedMatch.path.includes("courses/:courseID")) {
      if (!isAuth || !checkIsRouteAllowed()) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.location.href = `/landing/courses/${rest.computedMatch.params.courseID}`;
      }
    }
  }
  return (
    <Route
      {...rest}
      render={() =>
        (isAuth && checkIsRouteAllowed()) || !isPrivate ? content : <Redirect to={redirectUrl} />
      }
    />
  );
};

export default RouteWithLayout;
