import instance from "api/instance";

export type ApiSetFileRequestType = {
  data: {
    status: 200 | 401 | 422 | 404;
    success: boolean;
    data?: ApiFileType;
  };
};

interface ApiSetFileType {
  file: string | File;
  name?: string;
  size?: number;
  is_public?: boolean;
}

const apiUploadFile = async (params: ApiSetFileType): Promise<ApiSetFileRequestType> => {
  let formData;

  if (typeof params.file !== "string") {
    formData = new FormData();

    formData.append("file", params.file);
    formData.append("name", params.name);
    formData.append("size", params.size);
    if (params.is_public) formData.append("is_public", 1);
  } else {
    formData = { ...params };
  }

  return instance.post("file", formData, {
    headers: { "Content-Type": "multipart/form-data" }
  });
};

export default apiUploadFile;

export type ApiFileType = { id: number; path: string; name: string; size: number };
