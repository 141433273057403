import classNames from "classnames/bind";
import React, { DetailedHTMLProps, InputHTMLAttributes, forwardRef } from "react";

import { IMaskInput } from "react-imask";

import Styles from "./Input.module.scss";

const cnb = classNames.bind(Styles);

type Props = {
  error?: string;
  label?: string;
  mask?: string;
  onAccept?: (value: string) => void;
} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export const Input = forwardRef<HTMLInputElement, Props>(
  ({ className, error, label, ...props }, ref) => {
    const classes = cnb("input", {
      error: Boolean(error)
    });

    const Component = props.mask ? IMaskInput : "input";

    return (
      <div className={cnb(className)}>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <Component placeholder=' ' type='text' ref={ref} {...props} className={classes} />
        {error && <div className={cnb("errorInfo")}>{error}</div>}
      </div>
    );
  }
);

Input.displayName = "Input";
