import { useEffect, useState } from "react";
import { getScrollBarWidth } from "./useShowScroll";

const useAnimatedModal = (isVisible: boolean, closeModal?: () => void): AnimatedModalReturnType => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpened, setIsOpened] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsOpen(isVisible);
      setIsOpened(true);
    }, 0);

    const html = document.querySelector("html")!;

    if (isVisible) {
      html.style.overflow = "hidden";
      html.style.paddingRight = `${getScrollBarWidth()}px`;
    } else {
      html.style.overflow = "auto";
      html.style.paddingRight = "0";
    }

    return () => {
      html.style.overflow = "auto";
      html.style.paddingRight = "0";
    };
  }, [isVisible]);

  useEffect(() => {
    if (isOpen === false && isOpened) {
      setTimeout(() => {
        closeModal?.();
      }, 350);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return { isOpen, setIsOpen };
};

export default useAnimatedModal;

export type AnimatedModalReturnType = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
