import apiUploadFile, { ApiSetFileRequestType } from "api/general";
import { ModalType, PageType } from "reducers/AppReducer/types";
import { getTranslation } from "src/providers/I18nProvider";
import { createNotification } from "src/providers/NotificationsProvider";
import {
  CLEAR_STORE,
  SET_HIDE_FOOTER,
  SET_MODAL,
  SET_NAV_HIDE,
  SET_NAV_WIDTH,
  SET_PAGE,
  SET_STORE_VERSION
} from "store/constants/app";
import { AppThunk } from "store/index";
import {
  ActionClearStoreType,
  ActionHideFooter,
  ActionSetModalType,
  ActionSetNavHideType,
  ActionSetNavWidthType,
  ActionSetPageType,
  ActionSetStoreVersionType
} from "./types";

export const actionSetModal = (modal: ModalType): ActionSetModalType => ({
  type: SET_MODAL,
  modal
});

export const ActionSetNavWidth = (minimize: boolean): ActionSetNavWidthType => ({
  type: SET_NAV_WIDTH,
  minimize
});

export const ActionSetNavHide = (hide: boolean): ActionSetNavHideType => ({
  type: SET_NAV_HIDE,
  hide
});

export const actionSetPage = (page: PageType): ActionSetPageType => ({
  type: SET_PAGE,
  page
});

export const actionHideFooter = (hide: boolean): ActionHideFooter => ({
  type: SET_HIDE_FOOTER,
  hide
});

export const actionClearStore = (): ActionClearStoreType => ({
  type: CLEAR_STORE
});

export const actionSetStoreVersion = (version: number): ActionSetStoreVersionType => ({
  type: SET_STORE_VERSION,
  version
});

export const actionSetFile = (
  setLoading: (value: boolean) => void,
  file: string | File,
  name?: string,
  size?: number,
  callBack?: (e: ApiSetFileRequestType) => void,
  is_public?: boolean
): AppThunk => {
  return () => {
    setLoading(true);
    apiUploadFile({ file, name, size, is_public })
      .then((e) => {
        if (callBack) callBack(e);
        return e;
      })
      .catch((e) => {
        if (callBack) callBack(e);
        createNotification("error", getTranslation(`file.error.upload`));
        return e;
      })
      .finally(() => {
        setLoading(false);
      });
  };
};
