import classNames from "classnames/bind";
import ModalWrapper from "components/ModalWrapper/ModalWrapper";
import React from "react";
import { useSearchParams } from "src/hooks/useSearchParams";
import { useHistory, useLocation } from "react-router-dom";
import { Button } from "src/shared/ui/Button/Button";
import Styles from "./WelcomeModal.module.scss";

const cnb = classNames.bind(Styles);

export const WelcomeModal = () => {
  const history = useHistory();
  const location = useLocation();

  const searchParams = useSearchParams();
  const open = Boolean(searchParams.get("showWelcomeModal"));

  return (
    <ModalWrapper
      isVisible={open}
      closeModal={() => {
        history.replace(location.pathname);
      }}
      submit={() => {}}
      isLoading={false}
      showHeader
      customControls={() => <></>}
      className={cnb("modalFitWelcome")}
      containerClassName={cnb("modalFitWelcome")}
      contentClassName={cnb("main")}
    >
      <h2 className={cnb("title")}>Регистрация прошла успешно</h2>

      <Button
        style={{
          maxWidth: 300,
          margin: "30px auto 32px auto",
          textAlign: "center"
        }}
        type='submit'
        onClick={() => history.replace(location.pathname)}
      >
        Приступить к обучению
      </Button>
    </ModalWrapper>
  );
};
