/* eslint-disable react/no-unescaped-entities */
import classNames from "classnames/bind";
import talantaLogo from "image/talanta-education.svg";
import React from "react";
import SVG from "react-inlinesvg";
import { NavLink } from "react-router-dom";
import Styles from "./Footer.module.scss";

const cnb = classNames.bind(Styles);

const firstColumn = [
  {
    name: "О компании",
    path: "/#"
  },
  {
    name: "Авторам курсов",
    path: "/#"
  },
  {
    name: "Школам",
    path: "/#"
  },
  {
    name: "Все программы",
    path: "/#"
  }
];

const secondColumn = [
  {
    name: "Новые профессии",
    path: "/#"
  },
  {
    name: "Корпоративное обучение",
    path: "/#"
  },
  {
    name: "Профессии будущего",
    path: "/#"
  },
  {
    name: "Тесты",
    path: "/#"
  }
];

const thirdColumn = [
  {
    name: "Реквизиты",
    path: "/requisites"
  },
  {
    name: "Контакты",
    path: "/contacts"
  },
  {
    name: "Правовая информация",
    path: "/legalInformation"
  },
  {
    name: "Сертификаты",
    path: "/#"
  }
];

const Footer: React.FC = () => {
  return (
    <div className={cnb("container")}>
      <div className={cnb("inner")}>
        <div className={cnb("column", "columnWithLogo")}>
          <div>
            <SVG style={{ marginBottom: 8 }} src={talantaLogo} />
            <p className={cnb("footerInfoSubtitle", "policy")}>© 2023. Все права защищены</p>
          </div>
          <div>
            <a className={cnb("footerInfoTitle")} href='mailto:info@talanta.pro'>
              info@talanta.pro
            </a>
            <p className={cnb("footerInfoSubtitle")}>Связь с нами </p>
          </div>
        </div>
        <div className={cnb("columnWrapper", "gridRepeat")}>
          <div className={cnb("column")}>
            <div className={cnb("title")}>Сотрудничество</div>
            {firstColumn.map((item) => {
              return (
                <NavLink
                  rel='noreferrer'
                  to={item.path}
                  className={cnb("item")}
                  key={item.name + item.path}
                >
                  {item.name}
                </NavLink>
              );
            })}
          </div>
          {/* <div className={cnb("column")}>
            <div className={cnb("title")}>Обучение</div>
            {secondColumn.map((item) => {
              return (
                <NavLink
                  rel='noreferrer'
                  to={item.path}
                  className={cnb("item")}
                  key={item.name + item.path}
                >
                  {item.name}
                </NavLink>
              );
            })}
          </div> */}
          <div className={cnb("column")}>
            <div className={cnb("title")}>Информация</div>
            <div className={cnb("column", "gridRepeat")}>
              {thirdColumn.map((item) => {
                return (
                  <NavLink
                    rel='noreferrer'
                    to={item.path}
                    className={cnb("item")}
                    key={item.name + item.path}
                  >
                    {item.name}
                  </NavLink>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
