import { Roles } from "reducers/LoginReducer/types";

export const getDefaultPath = (role: Roles | undefined): string => {
  switch (role) {
    case Roles.STUDENT:
      return "/myCourses";
    case Roles.SCHOOL_OWNER:
    case Roles.MANAGER:
    case Roles.ADMIN:
      return "/createdCourses";
    case Roles.TEACHER:
      return "/availableCourses";
    default:
      return "/profile";
  }
};

export const formatRole = (roleName: string): Roles => {
  switch (roleName) {
    case "admin":
      return Roles.ADMIN;
    case "school_owner":
      return Roles.SCHOOL_OWNER;
    case "manager":
      return Roles.MANAGER;
    case "student":
      return Roles.STUDENT;
    case "teacher":
      return Roles.TEACHER;
    default:
      return Roles.SCHOOL_OWNER;
  }
};
