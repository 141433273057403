export const ACTION_WITHOUT_MODULE = "ACTION_WITHOUT_MODULE";
export const ACTION_WITH_MODULE = "ACTION_WITH_MODULE";
export const ACTION_WITH_LESSON = "ACTION_WITH_LESSON";
export const ACTION_WITH_LESSON_CONTENT = "ACTION_WITH_LESSON_CONTENT";
export const ACTION_WITH_TASK = "ACTION_WITH_TASK";
export const ACTION_WITH_TASK_CONTENT = "ACTION_WITH_TASK_CONTENT";
export const ADD_MODULES_TO_COURSE = "ADD_MODULES_TO_COURSE";
export const COURSE_LOADING = "COURSE_LOADING";
export const SET_COMPLETED_BLOCK = "SET_COMPLETED_BLOCK";
export const SET_STATISTICS_START_DATE = "SET_STATISTICS_START_DATE";
export const SET_TASKS_EVALUATION = "SET_TASKS_EVALUATION";
