import classNames from "classnames";
import Footer from "components/Footer/Footer";
import Nav from "components/Nav/Nav";
import PageContainer from "components/PageContainer/PageContainer";
import { WelcomeModal } from "components/WelcomeModal/WelcomeModal";
import { WelcomeModalFillData } from "components/WelcomeModalFillData/WelcomeModalFillData";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/index";

export type PagePropsDefault = {
  pageClassName?: string;
  showLeftSide?: boolean;
  leftSideIsMini?: boolean;
  showFooter?: boolean;
  withPageContainer?: boolean;
  isPrivate?: boolean;
};

const Layouts: React.FC<PagePropsDefault> = ({
  children,
  leftSideIsMini = false,
  showLeftSide = true,
  showFooter = true,
  withPageContainer = true,
  pageClassName,
  isPrivate
}) => {
  const { login } = useSelector((state: RootState) => state);
  const { isAuth } = login;

  return (
    <>
      <div className={classNames("page", pageClassName)}>
        <div className='pageCenter'>
          <div className={classNames("mainWrapper")}>
            {showLeftSide ? <Nav isHiding={leftSideIsMini} /> : null}
            <div
              style={isPrivate ? { maxWidth: "100%" } : {}}
              className={classNames("container", pageClassName)}
            >
              {withPageContainer ? (
                <PageContainer leftSideIsMini={!showLeftSide || leftSideIsMini}>
                  {children}
                </PageContainer>
              ) : (
                children
              )}
            </div>
          </div>
          {showFooter ? <Footer /> : null}
        </div>
        <div className={classNames("gradient")}>
          <svg
            width='288'
            height='317'
            viewBox='0 0 288 317'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <g opacity='0.3'>
              <g filter='url(#filter0_f_7712_4622)'>
                <ellipse
                  cx='119.884'
                  cy='102.243'
                  rx='119.884'
                  ry='102.243'
                  transform='matrix(-0.857563 0.51438 0.51438 0.857563 361.602 -135)'
                  fill='url(#paint0_linear_7712_4622)'
                />
              </g>
              <g filter='url(#filter1_f_7712_4622)'>
                <ellipse
                  cx='101.717'
                  cy='74.8119'
                  rx='101.717'
                  ry='74.8119'
                  transform='matrix(-0.857563 0.51438 0.51438 0.857563 326.133 -75.9258)'
                  fill='url(#paint1_linear_7712_4622)'
                />
              </g>
            </g>
            <defs>
              <filter
                id='filter0_f_7712_4622'
                x='0.717712'
                y='-288.023'
                width='621.338'
                height='604.734'
                filterUnits='userSpaceOnUse'
                colorInterpolationFilters='sRGB'
              >
                <feFlood floodOpacity='0' result='BackgroundImageFix' />
                <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
                <feGaussianBlur stdDeviation='97.5826' result='effect1_foregroundBlur_7712_4622' />
              </filter>
              <filter
                id='filter1_f_7712_4622'
                x='84.4409'
                y='-139.819'
                width='385.892'
                height='360.741'
                filterUnits='userSpaceOnUse'
                colorInterpolationFilters='sRGB'
              >
                <feFlood floodOpacity='0' result='BackgroundImageFix' />
                <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
                <feGaussianBlur stdDeviation='48.7913' result='effect1_foregroundBlur_7712_4622' />
              </filter>
              <linearGradient
                id='paint0_linear_7712_4622'
                x1='119.884'
                y1='0'
                x2='119.884'
                y2='204.486'
                gradientUnits='userSpaceOnUse'
              >
                <stop stopColor='#FF7BCA' />
                <stop offset='1' stopColor='#FFC56F' stopOpacity='0.46' />
              </linearGradient>
              <linearGradient
                id='paint1_linear_7712_4622'
                x1='101.717'
                y1='0'
                x2='101.717'
                y2='149.624'
                gradientUnits='userSpaceOnUse'
              >
                <stop stopColor='#F566C5' />
                <stop offset='1' stopColor='#F58A25' stopOpacity='0' />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>

      {isAuth && (
        <>
          <WelcomeModalFillData />
          <WelcomeModal />
        </>
      )}
    </>
  );
};

export default Layouts;
