import instance from "api/instance";
import { PaginationType } from "reducers/ProfileReducer/types";
import { CompilationType } from "./compilation";

export interface ApiProfileData {
  email: string;
  school_id: number[];
  permissions: unknown[];
  compilation: CompilationType;
  user_id: number;
  roles: { id: number; name: string }[];
  profile: Partial<ApiProfileType>;
  name: string;
  surname: string;
  phone: string;
  offers: ProfileOffers[];
}

export type ProfileOffers = {
  school: string;
  offer: { name: string; path: string };
};

export type ApiProfileType = {
  id: number;
  name: string;
  surname: string;
  patronymic: string;
  company_name: string;
  profile_picture: string | null;
  city: string | null;
  phone: string | null;
  work: string | null;
  date_of_birth: string | null;
  gender: number | null;
  education_id: number | null;
  timezone_id: number | null;
  language_id: number | null;
  user_id: number;
};

export interface ApiProfileResponse {
  data: {
    data: ApiProfileData;
  };
}

export async function apiGetProfile(): Promise<ApiProfileResponse> {
  return instance.get("my-profile");
}

export interface UpdateProfileVariables {
  userId: number;
  profileData: Partial<ProfileDataType>;
}

export interface ProfileDataType {
  name: string;
  surname: string;
  patronymic: string;
  company_name: string;
  profile_picture: string;
  city: string;
  phone: string;
  work: string;
  // date_of_birth: string;
  gender: number;
  education_id?: number;
  timezone_id?: number;
  language_id?: number;
}

export interface ProfileResponseType {
  data: {
    data: ProfileDataType;
  };
}
export async function apiUpdateProfile(
  userId: number,
  profileData: Partial<ProfileDataType>
): Promise<ProfileResponseType> {
  return instance.put(`profile/${userId}`, profileData);
}

export async function apiGetEducation(): Promise<{
  data: { status: number; success: boolean; data: { id: number | string; name: string }[] };
}> {
  return instance.get("education");
}

export type TimezoneType = {
  id: number | string;
  name: string;
  offset: string;
};

export async function apiGetTimezone(
  page = 1,
  filter?: string
): Promise<{
  data: {
    status: number;
    success: boolean;
    data: TimezoneType[];
    pagination: PaginationType;
  };
}> {
  return instance.get(`timezone`, { params: { page, filter } });
}

export type UpdatePasswordResponse = {
  data: { status: 200 | 401 | 422; success: boolean; errors: { validation: unknown } };
};

export interface UpdatePasswordVariables {
  oldPassword: string;
  newPassword: string;
  repeatPassword: string;
}

export interface ResetPasswordVariables {
  email: string;
  password: string;
  token: string;
}

export async function apiUpdatePassword(
  oldPassword: string,
  newPassword: string,
  newPasswordConfirm: string
): Promise<UpdatePasswordResponse> {
  return instance.post("password/update", {
    old_password: oldPassword,
    new_password: newPassword,
    new_password_confirmation: newPasswordConfirm
  });
}

export async function apiResetPassword(
  email: string,
  password: string,
  token: string
): Promise<UpdatePasswordResponse> {
  return instance.post("password/reset", {
    email,
    password,
    token
  });
}
