import { apiUpdateProfile, ProfileResponseType, UpdateProfileVariables } from "api/profile";
import { useMutation, UseMutationResult } from "react-query";
import { createNotification } from "src/providers/NotificationsProvider";
import { queryClient } from "src/providers/ReactQueryProvider";
import Queries from "../queries/queriesList";

const useUpdateProfileData = (): UseMutationResult<
  ProfileResponseType,
  unknown,
  UpdateProfileVariables,
  unknown
> => {
  return useMutation<ProfileResponseType, unknown, UpdateProfileVariables, unknown>(
    (data: UpdateProfileVariables) => apiUpdateProfile(data.userId, data.profileData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(Queries.PROFILE_DATA);
        createNotification("success", "Изменения сохранены");
      }
    }
  );
};

export default useUpdateProfileData;
