import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { APP_VERSION } from "src/appVersion";
import { actionClearStore } from "store/actions/app";
import { RootState } from "store/index";
import Routes from "../routing/Routes";
import { queryClient } from "./ReactQueryProvider";

const AuthProvider: React.FC = (): React.ReactElement => {
  const { login } = useSelector((state: RootState) => state);
  const { isAuth, role } = login;
  const dispatch = useDispatch();

  if (typeof localStorage.APP_VERSION === "undefined" || localStorage.APP_VERSION === null) {
    localStorage.setItem("APP_VERSION", APP_VERSION);
  }

  if (localStorage.APP_VERSION !== APP_VERSION) {
    localStorage.clear();
    dispatch(actionClearStore());
    queryClient.getQueryCache().clear();
  }

  return <Routes role={role} isAuth={isAuth} />;
};

export default React.memo(AuthProvider);
