import { AppActionTypes } from "store/actions/app/types";
import {
  SET_MODAL,
  SET_NAV_HIDE,
  SET_NAV_WIDTH,
  SET_PAGE,
  SET_HIDE_FOOTER,
  SET_STORE_VERSION
} from "../../constants/app";
import { AppInitialStateType } from "./types";

const initialState: AppInitialStateType = {
  modal: "disabled",
  navMinimized: false,
  baseURL: process.env.BASE_URL,
  navHide: false,
  page: undefined,
  hideFooter: false,
  storeVersion: 1
};

const AppReducer = (state = initialState, action: AppActionTypes): AppInitialStateType => {
  switch (action.type) {
    case SET_MODAL: {
      return {
        ...state,
        modal: action.modal
      };
    }
    case SET_NAV_WIDTH: {
      return {
        ...state,
        navMinimized: action.minimize
      };
    }
    case SET_NAV_HIDE: {
      return {
        ...state,
        navHide: action.hide
      };
    }
    case SET_PAGE: {
      return {
        ...state,
        page: action.page
      };
    }
    case SET_HIDE_FOOTER: {
      return {
        ...state,
        hideFooter: action.hide
      };
    }
    case SET_STORE_VERSION: {
      return {
        ...state,
        storeVersion: action.version
      };
    }
    default:
      return state;
  }
};

export default AppReducer;
