import { isExactPassage } from "src/utils/passage";
import { PassageActionTypes } from "store/actions/passages/types";
import {
  SET_CURRENT_PASSAGE_ID,
  SET_PASSAGE,
  SET_PASSAGE_TEST,
  CHANGE_PASSAGE_TEST,
  SET_TEST_PASSAGE_ID,
  SET_CONTINUED_LESSON_ID
} from "../../constants/passage";
import { PassageInitialStateType } from "./types";

const initialState: PassageInitialStateType = {
  passageList: [],
  coursePassageList: [],
  passageTestList: [],
  continuedLessonID: null
};

const PassageReducer = (
  state = initialState,
  action: PassageActionTypes
): PassageInitialStateType => {
  switch (action.type) {
    case SET_PASSAGE: {
      const { courseID, lessonID, taskID, contentBlockID } = action.passage;

      return {
        ...state,
        passageList: !state.passageList.find((item) =>
          isExactPassage(item, courseID, lessonID, taskID, contentBlockID)
        )
          ? state.passageList.concat(action.passage)
          : state.passageList.map((passage) => {
              if (isExactPassage(passage, courseID, lessonID, taskID, contentBlockID)) {
                return {
                  ...action.passage,
                  startedAt: passage.startedAt ? passage.startedAt : action.passage.startedAt
                };
              }
              return passage;
            })
      };
    }
    case SET_CURRENT_PASSAGE_ID: {
      return {
        ...state,
        coursePassageList: !state.coursePassageList.find(
          (item) => item.courseID === action.coursePassage.courseID
        )
          ? state.coursePassageList.concat(action.coursePassage)
          : state.coursePassageList.map((coursePassage) => {
              if (coursePassage.courseID === action.coursePassage.courseID) {
                return { ...coursePassage, id: action.coursePassage.id };
              }
              return coursePassage;
            })
      };
    }
    case SET_PASSAGE_TEST: {
      const { courseID, lessonID, taskID, contentBlockID } = action.payload;

      return {
        ...state,
        passageTestList: !state.passageTestList.find((test) =>
          isExactPassage(test, courseID, lessonID, taskID, contentBlockID)
        )
          ? state.passageTestList.concat(action.payload)
          : state.passageTestList.map((test) => {
              if (isExactPassage(test, courseID, lessonID, taskID, contentBlockID)) {
                return { ...action.payload };
              }
              return test;
            })
      };
    }
    case CHANGE_PASSAGE_TEST: {
      const { courseID, lessonID, taskID, contentBlockID, question } = action.payload;

      return {
        ...state,
        passageTestList: state.passageTestList.map((test) => {
          if (isExactPassage(test, courseID, lessonID, taskID, contentBlockID)) {
            return {
              ...test,
              questions: !test.questions.find((quest) => quest.questionID === question.questionID)
                ? test.questions.concat(question)
                : test.questions.map((item) => {
                    if (item.questionID === question.questionID) {
                      return {
                        ...item,
                        answerIDList: question.answerIDList
                      };
                    }
                    return item;
                  })
            };
          }
          return test;
        })
      };
    }
    case SET_TEST_PASSAGE_ID: {
      const { courseID, lessonID, taskID, contentBlockID, testPassageID } = action.payload;

      return {
        ...state,
        passageTestList: state.passageTestList.find((test) =>
          isExactPassage(test, courseID, lessonID, taskID, contentBlockID)
        )
          ? state.passageTestList.map((test) => {
              if (isExactPassage(test, courseID, lessonID, taskID, contentBlockID)) {
                return {
                  ...test,
                  testPassageID
                };
              }
              return test;
            })
          : state.passageTestList
      };
    }
    case SET_CONTINUED_LESSON_ID: {
      return {
        ...state,
        continuedLessonID: action.payload
      };
    }
    default:
      return state;
  }
};

export default PassageReducer;
