/* eslint-disable import/no-extraneous-dependencies */
import { AppInitialStateType } from "reducers/AppReducer/types";
import { ConstructorInitialStateType } from "reducers/ConstructorReducer/types";
import { CourseGeneralState } from "reducers/CourseGeneralReducer/types";
import { CourseInitialState } from "reducers/CourseReducer/types";
import { CreatedCourseListInitialState } from "reducers/CreatedCourseReducer/types";
import { LoginInitialStateType } from "reducers/LoginReducer/types";
import { ProfileType } from "reducers/ProfileReducer/types";
import { Action, applyMiddleware, combineReducers, createStore } from "redux";
import AppReducer from "reducers/AppReducer";
import ConstructorReducer from "reducers/ConstructorReducer";
import CourseGeneralReducer from "reducers/CourseGeneralReducer";
import CoursesReducer from "reducers/CourseReducer";
import CreatedCourseReducer from "reducers/CreatedCourseReducer";
import LoginReducer from "reducers/LoginReducer";
import PassageReducer from "reducers/PassageReducer";
import { PassageInitialStateType } from "reducers/PassageReducer/types";
import ProfileReducer from "reducers/ProfileReducer";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import thunkMiddleware, { ThunkAction } from "redux-thunk";
import { CLEAR_STORE } from "./constants/app";

const allReducers = combineReducers({
  app: AppReducer,
  login: LoginReducer,
  constructorReducer: ConstructorReducer,
  courseReducer: CoursesReducer,
  courseGeneralReducer: CourseGeneralReducer,
  profileReducer: ProfileReducer,
  createdCoursesReducer: CreatedCourseReducer,
  passageReducer: PassageReducer
});

const rootReducer = (state, action) => {
  let newState = state;
  if (action.type === CLEAR_STORE) {
    newState = undefined;
  }

  return allReducers(newState, action);
};

export const loadState = (): RootState | undefined => {
  try {
    const serializedState = localStorage.getItem("state");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = <T>(state: T): void => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("state", serializedState);
  } catch {
    // ignore write errors
  }
};
const persistedState = loadState();

const store = createStore(
  rootReducer,
  persistedState,
  composeWithDevTools(applyMiddleware(thunkMiddleware))
);

store.subscribe(() => {
  saveState({
    app: store.getState().app,
    login: store.getState().login,
    constructorReducer: store.getState().constructorReducer,
    courseReducer: store.getState().courseReducer,
    courseGeneralReducer: store.getState().courseGeneralReducer,
    profileReducer: store.getState().profileReducer,
    passageReducer: store.getState().passageReducer,
    createdCoursesReducer: store.getState().createdCoursesReducer
  });
});

export interface RootState {
  app: AppInitialStateType;
  login: LoginInitialStateType;
  constructorReducer: ConstructorInitialStateType;
  courseReducer: CourseInitialState;
  courseGeneralReducer: CourseGeneralState;
  profileReducer: ProfileType;
  createdCoursesReducer: CreatedCourseListInitialState;
  passageReducer: PassageInitialStateType;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<any>>;

export default store;
