import React, { useEffect } from "react";
import { Button } from "src/shared/ui/Button/Button";
import MainHeader from "./MainHeader/MainHeader";
import Title from "./Title/Title";

export function setWithExpiry(key, value, ttl) {
  const item = {
    value,
    expiry: new Date().getTime() + ttl
  };
  localStorage.setItem(key, JSON.stringify(item));
}

export function getWithExpiry(key) {
  const itemString = window.localStorage.getItem(key);
  if (!itemString) return null;
  const item = JSON.parse(itemString);
  const isExpired = new Date().getTime() > item.expiry;
  if (isExpired) {
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
}

export function ErrorFallback({ error, resetErrorBoundary }) {
  useEffect(() => {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (error?.message && chunkFailedMessage.test(error.message)) {
      if (!getWithExpiry("chunk_failed")) {
        setWithExpiry("chunk_failed", "true", 10000);
        window.location.reload();
      }
    }
  }, [error]);

  const reloadPage = () => {
    window.location.reload();
  };

  const goToMain = () => {
    window.location.href = "/";
    resetErrorBoundary();
  };

  return (
    <>
      <MainHeader />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 32,
          justifyContent: "center",
          alignItems: "center",
          flexGrow: 1
        }}
      >
        <Title name='Что-то пошло не так' />
        <div style={{ display: "flex", gap: 16 }}>
          <Button onClick={goToMain}>Перейти на главную</Button>
          <Button variant='outlined' onClick={reloadPage}>
            Обновить страницу
          </Button>
        </div>
      </div>
    </>
  );
}
