import classNames from "classnames/bind";
import { Spinner } from "components/Icons/Icons";
import React from "react";
import { PolymorphicComponentProps } from "src/shared/types/PolymorphicComponentProps";
import Styles from "./Button.module.scss";

const cnb = classNames.bind(Styles);

type Props<C extends React.ElementType> = {
  variant?: "default" | "outlined" | "flat" | "error";
  loading?: boolean;
} & PolymorphicComponentProps<C>;

export const Button = <C extends React.ElementType = "button">({
  as,
  color,
  size,
  className,
  disabled,
  loading,
  children,
  variant = "default",
  ...props
}: Props<C>) => {
  const Component = as || "button";

  // TODO delete app-button after normalize button for landing
  const classes = `app-button ${cnb(
    "button",
    variant,
    disabled,
    loading && "buttonLoading"
  )} ${className}`;

  return (
    <Component {...props} disabled={disabled || loading} className={classes}>
      {children}

      {loading ? (
        <span className={cnb("loader")}>
          <Spinner />
        </span>
      ) : null}
    </Component>
  );
};
