import classNames from "classnames/bind";
import React from "react";
import Styles from "./Picture.module.scss";

const cnb = classNames.bind(Styles);

interface Props {
  title?: string;
  imageUrl?: string;
  width?: number;
  height?: number;
  wrapperClass?: string;
  imgClass?: string;
  circle?: boolean;
}

const Picture: React.FC<Props> = ({
  title,
  imageUrl,
  wrapperClass,
  imgClass,
  width,
  height,
  circle
}) => {
  return (
    <picture title={title} className={wrapperClass}>
      {imageUrl === "" || imageUrl === "null" || !imageUrl ? null : (
        <img
          src={`${imageUrl}`}
          alt={title}
          width={width}
          height={height}
          className={cnb("loadedImgs", imgClass, { circle })}
          loading='lazy'
        />
      )}
    </picture>
  );
};

export default Picture;
