import { TaskPassageType, TestPassageType } from "reducers/PassageReducer/types";

export const isExactPassage = (
  passage: TaskPassageType | TestPassageType,
  courseID: string,
  lessonID: string,
  taskID: string,
  ContentBlockID: string
): boolean => {
  return (
    passage.courseID === courseID &&
    passage.lessonID === lessonID &&
    passage.taskID === taskID &&
    passage.contentBlockID === ContentBlockID
  );
};
