import Footer from "components/Footer/Footer";
import MainHeader from "components/MainHeader/MainHeader";
import React from "react";
import { Route, RouteProps } from "react-router-dom";
import bgImage from "../../image/bg.png";

type Props = {
  children: React.ReactNode;
} & RouteProps;

export const PublicLayout = ({ children, ...props }: Props) => {
  return (
    <Route
      {...props}
      render={() => (
        <>
          <main
            className='new-bg'
            style={{
              flexGrow: 1,
              backgroundImage: `url("${bgImage}")`
            }}
          >
            <MainHeader style={{ background: "F8F8FB" }} />

            {children}

            <Footer />
          </main>
        </>
      )}
    />
  );
};
