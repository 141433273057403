import classNames from "classnames/bind";
import Input from "components/Input/LegacyInput";
import ModalWrapper from "components/ModalWrapper/ModalWrapper";
import { useFormik } from "formik";
import { t } from "i18next";
import React, { useRef } from "react";
import useUpdateProfileData from "src/hooks/mutations/useUpdateProfileData";
import { useProfileData } from "src/hooks/queries/useProfileData";
import * as Yup from "yup";
import { getPhoneError } from "src/shared/ui/PhoneInput/lib";
import { PhoneInput } from "src/shared/ui/PhoneInput/PhoneInput";
import { Button } from "src/shared/ui/Button/Button";
import Styles from "./WelcomeModalFillData.module.scss";

const cnb = classNames.bind(Styles);

export const WelcomeModalFillData = () => {
  const { data } = useProfileData();
  const { mutate: mutateProfile, isLoading } = useUpdateProfileData();
  const itiRef = useRef<any>();

  const { user_id } = data;

  const { id, name, surname, phone } = data.profile;

  const open = Boolean(user_id) && Boolean(id) && [name, surname, phone].every((value) => !value);

  const initialGeneralValues = {
    firstName: name ?? "",
    lastName: surname ?? "",
    phone: phone ?? ""
  };

  const commonFormValidationSchema = Yup.object().shape({
    firstName: Yup.string()
      .required(t("errors.required"))
      .max(256, t("errors.longThen", { length: 256 })),
    lastName: Yup.string()
      .required(t("errors.required"))
      .max(256, t("errors.longThen", { length: 256 }))
  });

  const commonInfoForm = useFormik({
    initialValues: initialGeneralValues,
    enableReinitialize: true,
    validationSchema: commonFormValidationSchema,
    onSubmit: () => {
      const error = getPhoneError(itiRef.current.iti);

      if (error) {
        commonInfoForm.setFieldError("phone", error);
        return;
      }

      mutateProfile({
        userId: id!,
        profileData: {
          name: commonInfoForm.values.firstName,
          surname: commonInfoForm.values.lastName,
          phone: itiRef.current.iti.getNumber().slice(1)
        }
      });
    }
  });

  const { touched: commonTouched, errors: commonErrors } = commonInfoForm;

  return (
    <ModalWrapper
      isVisible={open}
      closeModal={() => {}}
      submit={() => {}}
      showHeader={false}
      isLoading
      customControls={() => <></>}
      className={cnb("modalFitWelcome")}
      containerClassName={cnb("modalFitWelcome")}
      contentClassName={cnb("main")}
    >
      <h2 className={cnb("title")}>Давайте познакомимся</h2>

      <form onSubmit={commonInfoForm.handleSubmit} className={cnb("form")}>
        <div className={cnb("wrapper")}>
          <Input
            id='firstName'
            name='firstName'
            title={t("profile.generalInformation.firstName")}
            setValue={commonInfoForm.handleChange}
            value={commonInfoForm.values.firstName}
            touched={commonTouched.firstName}
            error={commonErrors.firstName}
          />
          <Input
            id='lastName'
            name='lastName'
            title={t("profile.generalInformation.lastName")}
            setValue={commonInfoForm.handleChange}
            value={commonInfoForm.values.lastName}
            touched={commonTouched.lastName}
            error={commonErrors.lastName}
          />
        </div>

        <PhoneInput
          ref={itiRef}
          name='phone'
          onChange={commonInfoForm.handleChange}
          value={commonInfoForm.values.phone}
          error={commonErrors.phone}
        />

        <Button
          style={{
            maxWidth: 300,
            margin: "30px auto 32px auto"
          }}
          loading={isLoading}
          type='submit'
          onClick={() => {}}
        >
          Начать учиться
        </Button>
      </form>
    </ModalWrapper>
  );
};
