import React from "react";
import classNames from "classnames/bind";
import { NavLink } from "react-router-dom";
import NavMenu from "components/NavMenu/NavMenu";
import Styles from "./BurgerProfileContent.module.scss";
import { ThemeType } from "./BurgerHeader";
import { NavItem } from "../config";

const cnb = classNames.bind(Styles);

interface Props {
  theme?: ThemeType;
  list: Link[];
  items: NavItem[];
  currentPath: string;
  onlyIcons?: boolean;
  isActiveBurger: boolean;
  toggleMenuBurger?: (event: React.MouseEvent, link?: string) => void;
}
interface Link {
  icon: JSX.Element;
  path: string;
  name: string;
  onClick: () => void;
  enabled: boolean;
}

const BurgerProfileContent: React.FC<Props> = ({
  list,
  currentPath,
  onlyIcons,
  items,
  isActiveBurger,
  toggleMenuBurger
}) => {
  return (
    <div className={cnb("contentWrapper", { contentShow: isActiveBurger })}>
      <div className={cnb("contentInner")}>
        <NavMenu
          list={list}
          currentPath={currentPath}
          onlyIcons={onlyIcons}
          toggleMenuBurger={toggleMenuBurger}
        />
        <span className={cnb("middleBorder")} />
        <div className={cnb("otherButtons")}>
          {items.map(({ title, link, button, isExternalLink }) => {
            return (
              <>
                {isExternalLink ? (
                  <a href={link} key={`${title}-${link}`} className={cnb("bottomButton")}>
                    {title}
                  </a>
                ) : (
                  <NavLink
                    to={link}
                    onClick={(event) => toggleMenuBurger?.(event, link)}
                    className={cnb("bottomButton", { button })}
                    key={`${title}-${link}`}
                  >
                    {title}
                  </NavLink>
                )}
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default BurgerProfileContent;
