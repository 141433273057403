import classNames from "classnames/bind";
import { User } from "components/Icons/Icons";
import Loader from "components/Loader/Loader";
import Picture from "components/Picture/Picture";
import React from "react";
import { useProfileData } from "src/hooks/queries/useProfileData";
import definePath from "src/utils/defineImagePath";
import Styles from "./Avatar.module.scss";

const cnb = classNames.bind(Styles);
interface Props {
  name?: string;
  onlyImage?: boolean;
  isSmall?: boolean;
}

const Avatar: React.FC<Props> = ({ name, onlyImage, isSmall }) => {
  const { data: profile, isLoading } = useProfileData();
  const pathToImage = profile.profile?.profile_picture;
  const pathToAvatar = definePath(pathToImage as string);

  const getWidth = (isSmall?: boolean, onlyImage?: boolean): number | undefined => {
    if (!onlyImage) return 100;
    if (onlyImage) return 55;
    if (isSmall) return 40;
    return 55;
  };

  const getHeight = (isSmall?: boolean, onlyImage?: boolean): number | undefined => {
    if (onlyImage) return 55;
    if (!onlyImage) return 100;

    if (isSmall) return 40;
    return 55;
  };

  return (
    <div className={cnb("container")}>
      {!isLoading ? (
        <>
          {pathToAvatar && pathToAvatar.length ? (
            <Picture
              imageUrl={pathToAvatar}
              title='ava'
              height={getHeight(isSmall, onlyImage)}
              width={getWidth(isSmall, onlyImage)}
              imgClass={cnb("image")}
              circle
            />
          ) : (
            <div
              style={{ width: getWidth(isSmall, onlyImage), height: getHeight(isSmall, onlyImage) }}
              className={cnb("emptyImage", "image")}
            >
              <User
                className={cnb({
                  smallSvg: getWidth(isSmall, onlyImage) === 55
                })}
              />
            </div>
          )}
        </>
      ) : (
        <Loader small />
      )}
      {!onlyImage && <div className={cnb("profileName")}>{name}</div>}
    </div>
  );
};

export default Avatar;
