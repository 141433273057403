const ERRORS = [
  "Неверный номер",
  "Неверный код страны",
  "Слишком короткий номер",
  "Слишком длинный номер",
  "Неверный номер"
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getPhoneError = (iti: any) => {
  const isValid = iti.isValidNumber();

  if (isValid) return;

  const errorCode = iti.getValidationError();
  return errorCode < 0 || errorCode > 4 ? "Неверный номер" : ERRORS[errorCode];
};
